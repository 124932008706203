/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
}

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box;
}

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;
}

.mfp-preloader a {
  color: #CCC;
}

.mfp-preloader a:hover {
  color: #FFF;
}

.mfp-s-ready .mfp-preloader {
  display: none;
}

.mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close, button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
}

.mfp-close:hover, .mfp-close:focus {
  opacity: 1;
}

.mfp-close:active {
  top: 1px;
}

.mfp-close-btn-in .mfp-close {
  color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%;
}

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent;
}

.mfp-arrow:active {
  margin-top: -54px;
}

.mfp-arrow:hover, .mfp-arrow:focus {
  opacity: 1;
}

.mfp-arrow:before, .mfp-arrow:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent;
}

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}

.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7;
}

.mfp-arrow-left {
  left: 0;
}

.mfp-arrow-left:after {
  border-right: 17px solid #FFF;
  margin-left: 31px;
}

.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F;
}

.mfp-arrow-right {
  right: 0;
}

.mfp-arrow-right:after {
  border-left: 17px solid #FFF;
  margin-left: 39px;
}

.mfp-arrow-right:before {
  border-left: 27px solid #3F3F3F;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px;
}

.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000;
}

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
  line-height: 0;
}

.mfp-figure:after {
  content: '';
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444;
}

.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px;
}

.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }
  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box;
  }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.mm-menu, .mm-panels, .mm-panels > .mm-panel {
  margin: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 0;
  box-sizing: border-box;
}

.mm-btn, .mm-menu {
  box-sizing: border-box;
}

.mm-listview a, .mm-listview a:hover, .mm-navbar a, .mm-navbar a:hover {
  text-decoration: none;
}

.mm-listview .mm-next:before, .mm-listview > li:not(.mm-divider):after, .mm-next:after, .mm-prev:before {
  content: '';
  bottom: 0;
}

.mm-hidden {
  display: none !important;
}

.mm-menu, .mm-panels > .mm-panel:not(.mm-hidden) {
  display: block;
}

.mm-wrapper {
  overflow-x: hidden;
  position: relative;
}

.mm-menu {
  padding: 0;
  position: absolute;
  bottom: 0;
}

.mm-panels, .mm-panels > .mm-panel {
  background: inherit;
  border-color: inherit;
  position: absolute;
  bottom: 0;
}

.mm-btn, .mm-panel.mm-highest {
  z-index: 1;
}

.mm-panels {
  overflow: hidden;
}

.mm-panel {
  -webkit-transition: -webkit-transform .4s ease;
  transition: -webkit-transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease,-webkit-transform .4s ease;
  -webkit-transform: translate(100%, 0);
  -ms-transform: translate(100%, 0);
  transform: translate(100%, 0);
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

.mm-panel.mm-opened {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mm-panel.mm-subopened {
  -webkit-transform: translate(-30%, 0);
  -ms-transform: translate(-30%, 0);
  transform: translate(-30%, 0);
  -webkit-transform: translate3d(-30%, 0, 0);
  transform: translate3d(-30%, 0, 0);
}

.mm-panels > .mm-panel {
  -webkit-overflow-scrolling: touch;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 20px;
}

.mm-listview .mm-divider, .mm-listview > li > a, .mm-listview > li > span, .mm-navbar .mm-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.mm-panels > .mm-panel.mm-hasnavbar {
  padding-top: 40px;
}

.mm-panels > .mm-panel:after, .mm-panels > .mm-panel:before {
  content: '';
  display: block;
  height: 20px;
}

.mm-vertical .mm-panel {
  -webkit-transform: none !important;
  -ms-transform: none !important;
  transform: none !important;
}

.mm-listview .mm-vertical .mm-panel, .mm-vertical .mm-listview .mm-panel {
  display: none;
  padding: 10px 0 10px 10px;
}

.mm-listview .mm-vertical .mm-panel .mm-listview > li:last-child:after, .mm-vertical .mm-listview .mm-panel .mm-listview > li:last-child:after {
  border-color: transparent;
}

.mm-vertical li.mm-opened > .mm-panel, li.mm-vertical.mm-opened > .mm-panel {
  display: block;
}

.mm-listview > li.mm-vertical > .mm-next, .mm-vertical .mm-listview > li > .mm-next {
  height: 40px;
  bottom: auto;
}

.mm-listview > li.mm-vertical > .mm-next:after, .mm-vertical .mm-listview > li > .mm-next:after {
  top: 16px;
  bottom: auto;
}

.mm-listview > li.mm-vertical.mm-opened > .mm-next:after, .mm-vertical .mm-listview > li.mm-opened > .mm-next:after {
  -webkit-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  transform: rotate(225deg);
  right: 19px;
}

.mm-btn {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
}

.mm-clear:after, .mm-clear:before, .mm-close:after, .mm-close:before {
  content: '';
  border: 2px solid transparent;
  display: block;
  width: 5px;
  height: 5px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.mm-clear:before, .mm-close:before {
  border-right: none;
  border-bottom: none;
  right: 18px;
}

.mm-clear:after, .mm-close:after {
  border-left: none;
  border-top: none;
  right: 25px;
}

.mm-next:after, .mm-prev:before {
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  display: block;
  width: 8px;
  height: 8px;
  margin: auto;
  position: absolute;
  top: 0;
}

.mm-prev:before {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  left: 23px;
  right: auto;
}

.mm-next:after {
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
  right: 23px;
  left: auto;
}

.mm-navbar {
  border-bottom: 1px solid;
  border-color: inherit;
  text-align: center;
  line-height: 20px;
  height: 40px;
  padding: 0 40px;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.mm-navbar > * {
  display: block;
  padding: 10px 0;
}

.mm-navbar .mm-btn:first-child {
  left: 0;
}

.mm-navbar .mm-btn:last-child {
  text-align: right;
  right: 0;
}

.mm-panel .mm-navbar {
  display: none;
}

.mm-panel.mm-hasnavbar .mm-navbar {
  display: block;
}

.mm-listview, .mm-listview > li {
  list-style: none;
  display: block;
  padding: 0;
  margin: 0;
}

.mm-listview {
  font: inherit;
  font-size: 14px;
  line-height: 20px;
}

.mm-listview > li {
  position: relative;
}

.mm-listview > li, .mm-listview > li .mm-next, .mm-listview > li .mm-next:before, .mm-listview > li:after {
  border-color: inherit;
}

.mm-listview > li > a, .mm-listview > li > span {
  color: inherit;
  display: block;
  padding: 10px 10px 10px 20px;
  margin: 0;
}

.mm-listview > li:not(.mm-divider):after {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  display: block;
  position: absolute;
  right: 0;
  left: 20px;
}

.mm-listview .mm-next {
  background: rgba(3, 2, 1, 0);
  width: 50px;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
}

.mm-listview .mm-next:before {
  border-left-width: 1px;
  border-left-style: solid;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.mm-listview .mm-next + a, .mm-listview .mm-next + span {
  margin-right: 50px;
}

.mm-listview .mm-next.mm-fullsubopen {
  width: 100%;
}

.mm-listview .mm-next.mm-fullsubopen:before {
  border-left: none;
}

.mm-listview .mm-next.mm-fullsubopen + a, .mm-listview .mm-next.mm-fullsubopen + span {
  padding-right: 50px;
  margin-right: 0;
}

.mm-panels > .mm-panel > .mm-listview {
  margin: 20px -20px;
}

.mm-panels > .mm-panel > .mm-listview:first-child, .mm-panels > .mm-panel > .mm-navbar + .mm-listview {
  margin-top: -20px;
}

.mm-listview .mm-inset {
  list-style: disc inside;
  padding: 0 10px 15px 40px;
  margin: 0;
}

.mm-listview .mm-inset > li {
  padding: 5px 0;
}

.mm-listview .mm-divider {
  font-size: 10px;
  text-transform: uppercase;
  text-indent: 20px;
  line-height: 25px;
}

.mm-listview .mm-spacer {
  padding-top: 40px;
}

.mm-listview .mm-spacer > .mm-next {
  top: 40px;
}

.mm-listview .mm-spacer.mm-divider {
  padding-top: 25px;
}

.mm-menu {
  background: #f3f3f3;
  border-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.75);
}

.mm-menu .mm-navbar a, .mm-menu .mm-navbar > * {
  color: rgba(0, 0, 0, 0.3);
}

.mm-menu .mm-btn:after, .mm-menu .mm-btn:before {
  border-color: rgba(0, 0, 0, 0.3);
}

.mm-menu .mm-listview {
  border-color: rgba(0, 0, 0, 0.1);
}

.mm-menu .mm-listview > li .mm-next:after {
  border-color: rgba(0, 0, 0, 0.3);
}

.mm-menu .mm-listview > li a:not(.mm-next) {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0.5);
  tap-highlight-color: rgba(255, 255, 255, 0.5);
}

.mm-menu .mm-listview > li.mm-selected > a:not(.mm-next), .mm-menu .mm-listview > li.mm-selected > span {
  background: rgba(255, 255, 255, 0.5);
}

.mm-menu .mm-divider, .mm-menu .mm-listview > li.mm-opened.mm-vertical > .mm-panel, .mm-menu .mm-listview > li.mm-opened.mm-vertical > a.mm-next, .mm-menu.mm-vertical .mm-listview > li.mm-opened > .mm-panel, .mm-menu.mm-vertical .mm-listview > li.mm-opened > a.mm-next {
  background: rgba(0, 0, 0, 0.05);
}

.mm-page {
  box-sizing: border-box;
  position: relative;
}

.mm-slideout {
  -webkit-transition: -webkit-transform .4s ease;
  transition: -webkit-transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease,-webkit-transform .4s ease;
  z-index: 1;
}

html.mm-opened {
  overflow-x: hidden;
  position: relative;
}

html.mm-blocking, html.mm-blocking body {
  overflow: hidden;
}

html.mm-background .mm-page {
  background: inherit;
}

#mm-blocker {
  background: rgba(3, 2, 1, 0);
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
}

html.mm-blocking #mm-blocker {
  display: block;
}

.mm-menu.mm-offcanvas {
  z-index: 0;
  display: none;
  position: fixed;
  width: 80%;
  min-width: 140px;
  max-width: 440px;
}

.mm-menu.mm-offcanvas.mm-current {
  display: block;
}

.mm-menu.mm-offcanvas.mm-no-csstransforms.mm-current.mm-opened {
  z-index: 10;
}

html.mm-opening .mm-slideout {
  -webkit-transform: translate(80%, 0);
  -ms-transform: translate(80%, 0);
  transform: translate(80%, 0);
  -webkit-transform: translate3d(80%, 0, 0);
  transform: translate3d(80%, 0, 0);
}

@media all and (max-width: 175px) {
  html.mm-opening .mm-slideout {
    -webkit-transform: translate(140px, 0);
    -ms-transform: translate(140px, 0);
    transform: translate(140px, 0);
    -webkit-transform: translate3d(140px, 0, 0);
    transform: translate3d(140px, 0, 0);
  }
}

@media all and (min-width: 550px) {
  html.mm-opening .mm-slideout {
    -webkit-transform: translate(440px, 0);
    -ms-transform: translate(440px, 0);
    transform: translate(440px, 0);
    -webkit-transform: translate3d(440px, 0, 0);
    transform: translate3d(440px, 0, 0);
  }
}

.mm-menu.mm-offcanvas.mm-autoheight.mm-bottom, .mm-menu.mm-offcanvas.mm-autoheight.mm-top {
  max-height: 80%;
}

.mm-menu.mm-offcanvas.mm-autoheight.mm-bottom.mm-fullscreen, .mm-menu.mm-offcanvas.mm-autoheight.mm-top.mm-fullscreen {
  max-height: 100%;
}

.mm-menu.mm-offcanvas.mm-measureheight .mm-panel {
  display: block !important;
}

.mm-menu.mm-offcanvas.mm-measureheight > .mm-panels > .mm-panel {
  bottom: auto !important;
  height: auto !important;
}

.mm-menu.mm-columns {
  -webkit-transition-property: width,max-width,-webkit-transform;
  transition-property: width,max-width,-webkit-transform;
  transition-property: transform,width,max-width;
  transition-property: transform,width,max-width,-webkit-transform;
}

.mm-menu.mm-columns .mm-panels > .mm-panel {
  width: 100%;
  right: auto;
  display: none;
  -webkit-transition-property: width,-webkit-transform;
  transition-property: width,-webkit-transform;
  transition-property: transform,width;
  transition-property: transform,width,-webkit-transform;
}

.mm-menu.mm-columns .mm-panels > .mm-panel.mm-opened {
  border-right: 1px solid;
  border-color: inherit;
  display: block;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mm-menu.mm-columns .mm-panels > .mm-panel.mm-subopened {
  -webkit-transform: translate(-100%, 0);
  -ms-transform: translate(-100%, 0);
  transform: translate(-100%, 0);
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  z-index: 2;
}

.mm-menu.mm-columns .mm-panels > .mm-panel.mm-columns-0 {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mm-menu.mm-columns .mm-panels > .mm-panel.mm-opened:not([class*=mm-columns-]) ~ .mm-panel:not(.mm-opened).mm-columns-0 {
  display: block;
  -webkit-transform: translate(100%, 0);
  -ms-transform: translate(100%, 0);
  transform: translate(100%, 0);
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

.mm-menu.mm-offcanvas.mm-columns-0 {
  width: 80%;
  min-width: 140px;
  max-width: 0;
}

html.mm-opening.mm-columns-0 .mm-slideout {
  -webkit-transform: translate(80%, 0);
  -ms-transform: translate(80%, 0);
  transform: translate(80%, 0);
  -webkit-transform: translate3d(80%, 0, 0);
  transform: translate3d(80%, 0, 0);
}

@media all and (max-width: 175px) {
  html.mm-opening.mm-columns-0 .mm-slideout {
    -webkit-transform: translate(140px, 0);
    -ms-transform: translate(140px, 0);
    transform: translate(140px, 0);
    -webkit-transform: translate3d(140px, 0, 0);
    transform: translate3d(140px, 0, 0);
  }
}

@media all and (min-width: 0px) {
  html.mm-opening.mm-columns-0 .mm-slideout {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

html.mm-right.mm-opening.mm-columns-0 .mm-slideout {
  -webkit-transform: translate(-80%, 0);
  -ms-transform: translate(-80%, 0);
  transform: translate(-80%, 0);
  -webkit-transform: translate3d(-80%, 0, 0);
  transform: translate3d(-80%, 0, 0);
}

@media all and (max-width: 175px) {
  html.mm-right.mm-opening.mm-columns-0 .mm-slideout {
    -webkit-transform: translate(-140px, 0);
    -ms-transform: translate(-140px, 0);
    transform: translate(-140px, 0);
    -webkit-transform: translate3d(-140px, 0, 0);
    transform: translate3d(-140px, 0, 0);
  }
}

@media all and (min-width: 0px) {
  html.mm-right.mm-opening.mm-columns-0 .mm-slideout {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.mm-menu.mm-columns .mm-panels > .mm-panel.mm-columns-1 {
  -webkit-transform: translate(100%, 0);
  -ms-transform: translate(100%, 0);
  transform: translate(100%, 0);
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

.mm-menu.mm-columns .mm-panels > .mm-panel.mm-opened:not([class*=mm-columns-]) ~ .mm-panel:not(.mm-opened).mm-columns-1 {
  display: block;
  -webkit-transform: translate(200%, 0);
  -ms-transform: translate(200%, 0);
  transform: translate(200%, 0);
  -webkit-transform: translate3d(200%, 0, 0);
  transform: translate3d(200%, 0, 0);
}

.mm-menu.mm-offcanvas.mm-columns-1 {
  width: 80%;
  min-width: 140px;
  max-width: 440px;
}

html.mm-opening.mm-columns-1 .mm-slideout {
  -webkit-transform: translate(80%, 0);
  -ms-transform: translate(80%, 0);
  transform: translate(80%, 0);
  -webkit-transform: translate3d(80%, 0, 0);
  transform: translate3d(80%, 0, 0);
}

@media all and (max-width: 175px) {
  html.mm-opening.mm-columns-1 .mm-slideout {
    -webkit-transform: translate(140px, 0);
    -ms-transform: translate(140px, 0);
    transform: translate(140px, 0);
    -webkit-transform: translate3d(140px, 0, 0);
    transform: translate3d(140px, 0, 0);
  }
}

@media all and (min-width: 550px) {
  html.mm-opening.mm-columns-1 .mm-slideout {
    -webkit-transform: translate(440px, 0);
    -ms-transform: translate(440px, 0);
    transform: translate(440px, 0);
    -webkit-transform: translate3d(440px, 0, 0);
    transform: translate3d(440px, 0, 0);
  }
}

html.mm-right.mm-opening.mm-columns-1 .mm-slideout {
  -webkit-transform: translate(-80%, 0);
  -ms-transform: translate(-80%, 0);
  transform: translate(-80%, 0);
  -webkit-transform: translate3d(-80%, 0, 0);
  transform: translate3d(-80%, 0, 0);
}

@media all and (max-width: 175px) {
  html.mm-right.mm-opening.mm-columns-1 .mm-slideout {
    -webkit-transform: translate(-140px, 0);
    -ms-transform: translate(-140px, 0);
    transform: translate(-140px, 0);
    -webkit-transform: translate3d(-140px, 0, 0);
    transform: translate3d(-140px, 0, 0);
  }
}

@media all and (min-width: 550px) {
  html.mm-right.mm-opening.mm-columns-1 .mm-slideout {
    -webkit-transform: translate(-440px, 0);
    -ms-transform: translate(-440px, 0);
    transform: translate(-440px, 0);
    -webkit-transform: translate3d(-440px, 0, 0);
    transform: translate3d(-440px, 0, 0);
  }
}

.mm-menu.mm-columns-1 .mm-panels > .mm-panel {
  width: 100%;
}

.mm-menu.mm-columns .mm-panels > .mm-panel.mm-columns-2 {
  -webkit-transform: translate(200%, 0);
  -ms-transform: translate(200%, 0);
  transform: translate(200%, 0);
  -webkit-transform: translate3d(200%, 0, 0);
  transform: translate3d(200%, 0, 0);
}

.mm-menu.mm-columns .mm-panels > .mm-panel.mm-opened:not([class*=mm-columns-]) ~ .mm-panel:not(.mm-opened).mm-columns-2 {
  display: block;
  -webkit-transform: translate(300%, 0);
  -ms-transform: translate(300%, 0);
  transform: translate(300%, 0);
  -webkit-transform: translate3d(300%, 0, 0);
  transform: translate3d(300%, 0, 0);
}

.mm-menu.mm-offcanvas.mm-columns-2 {
  width: 80%;
  min-width: 140px;
  max-width: 880px;
}

html.mm-opening.mm-columns-2 .mm-slideout {
  -webkit-transform: translate(80%, 0);
  -ms-transform: translate(80%, 0);
  transform: translate(80%, 0);
  -webkit-transform: translate3d(80%, 0, 0);
  transform: translate3d(80%, 0, 0);
}

@media all and (max-width: 175px) {
  html.mm-opening.mm-columns-2 .mm-slideout {
    -webkit-transform: translate(140px, 0);
    -ms-transform: translate(140px, 0);
    transform: translate(140px, 0);
    -webkit-transform: translate3d(140px, 0, 0);
    transform: translate3d(140px, 0, 0);
  }
}

@media all and (min-width: 1100px) {
  html.mm-opening.mm-columns-2 .mm-slideout {
    -webkit-transform: translate(880px, 0);
    -ms-transform: translate(880px, 0);
    transform: translate(880px, 0);
    -webkit-transform: translate3d(880px, 0, 0);
    transform: translate3d(880px, 0, 0);
  }
}

html.mm-right.mm-opening.mm-columns-2 .mm-slideout {
  -webkit-transform: translate(-80%, 0);
  -ms-transform: translate(-80%, 0);
  transform: translate(-80%, 0);
  -webkit-transform: translate3d(-80%, 0, 0);
  transform: translate3d(-80%, 0, 0);
}

@media all and (max-width: 175px) {
  html.mm-right.mm-opening.mm-columns-2 .mm-slideout {
    -webkit-transform: translate(-140px, 0);
    -ms-transform: translate(-140px, 0);
    transform: translate(-140px, 0);
    -webkit-transform: translate3d(-140px, 0, 0);
    transform: translate3d(-140px, 0, 0);
  }
}

@media all and (min-width: 1100px) {
  html.mm-right.mm-opening.mm-columns-2 .mm-slideout {
    -webkit-transform: translate(-880px, 0);
    -ms-transform: translate(-880px, 0);
    transform: translate(-880px, 0);
    -webkit-transform: translate3d(-880px, 0, 0);
    transform: translate3d(-880px, 0, 0);
  }
}

.mm-menu.mm-columns-2 .mm-panels > .mm-panel {
  width: 50%;
}

.mm-menu.mm-columns .mm-panels > .mm-panel.mm-columns-3 {
  -webkit-transform: translate(300%, 0);
  -ms-transform: translate(300%, 0);
  transform: translate(300%, 0);
  -webkit-transform: translate3d(300%, 0, 0);
  transform: translate3d(300%, 0, 0);
}

.mm-menu.mm-columns .mm-panels > .mm-panel.mm-opened:not([class*=mm-columns-]) ~ .mm-panel:not(.mm-opened).mm-columns-3 {
  display: block;
  -webkit-transform: translate(400%, 0);
  -ms-transform: translate(400%, 0);
  transform: translate(400%, 0);
  -webkit-transform: translate3d(400%, 0, 0);
  transform: translate3d(400%, 0, 0);
}

.mm-menu.mm-offcanvas.mm-columns-3 {
  width: 80%;
  min-width: 140px;
  max-width: 1320px;
}

html.mm-opening.mm-columns-3 .mm-slideout {
  -webkit-transform: translate(80%, 0);
  -ms-transform: translate(80%, 0);
  transform: translate(80%, 0);
  -webkit-transform: translate3d(80%, 0, 0);
  transform: translate3d(80%, 0, 0);
}

@media all and (max-width: 175px) {
  html.mm-opening.mm-columns-3 .mm-slideout {
    -webkit-transform: translate(140px, 0);
    -ms-transform: translate(140px, 0);
    transform: translate(140px, 0);
    -webkit-transform: translate3d(140px, 0, 0);
    transform: translate3d(140px, 0, 0);
  }
}

@media all and (min-width: 1650px) {
  html.mm-opening.mm-columns-3 .mm-slideout {
    -webkit-transform: translate(1320px, 0);
    -ms-transform: translate(1320px, 0);
    transform: translate(1320px, 0);
    -webkit-transform: translate3d(1320px, 0, 0);
    transform: translate3d(1320px, 0, 0);
  }
}

html.mm-right.mm-opening.mm-columns-3 .mm-slideout {
  -webkit-transform: translate(-80%, 0);
  -ms-transform: translate(-80%, 0);
  transform: translate(-80%, 0);
  -webkit-transform: translate3d(-80%, 0, 0);
  transform: translate3d(-80%, 0, 0);
}

@media all and (max-width: 175px) {
  html.mm-right.mm-opening.mm-columns-3 .mm-slideout {
    -webkit-transform: translate(-140px, 0);
    -ms-transform: translate(-140px, 0);
    transform: translate(-140px, 0);
    -webkit-transform: translate3d(-140px, 0, 0);
    transform: translate3d(-140px, 0, 0);
  }
}

@media all and (min-width: 1650px) {
  html.mm-right.mm-opening.mm-columns-3 .mm-slideout {
    -webkit-transform: translate(-1320px, 0);
    -ms-transform: translate(-1320px, 0);
    transform: translate(-1320px, 0);
    -webkit-transform: translate3d(-1320px, 0, 0);
    transform: translate3d(-1320px, 0, 0);
  }
}

.mm-menu.mm-columns-3 .mm-panels > .mm-panel {
  width: 33.34%;
}

.mm-menu.mm-columns .mm-panels > .mm-panel.mm-columns-4 {
  -webkit-transform: translate(400%, 0);
  -ms-transform: translate(400%, 0);
  transform: translate(400%, 0);
  -webkit-transform: translate3d(400%, 0, 0);
  transform: translate3d(400%, 0, 0);
}

.mm-menu.mm-columns .mm-panels > .mm-panel.mm-opened:not([class*=mm-columns-]) ~ .mm-panel:not(.mm-opened).mm-columns-4 {
  display: block;
  -webkit-transform: translate(500%, 0);
  -ms-transform: translate(500%, 0);
  transform: translate(500%, 0);
  -webkit-transform: translate3d(500%, 0, 0);
  transform: translate3d(500%, 0, 0);
}

.mm-menu.mm-offcanvas.mm-columns-4 {
  width: 80%;
  min-width: 140px;
  max-width: 1760px;
}

html.mm-opening.mm-columns-4 .mm-slideout {
  -webkit-transform: translate(80%, 0);
  -ms-transform: translate(80%, 0);
  transform: translate(80%, 0);
  -webkit-transform: translate3d(80%, 0, 0);
  transform: translate3d(80%, 0, 0);
}

@media all and (max-width: 175px) {
  html.mm-opening.mm-columns-4 .mm-slideout {
    -webkit-transform: translate(140px, 0);
    -ms-transform: translate(140px, 0);
    transform: translate(140px, 0);
    -webkit-transform: translate3d(140px, 0, 0);
    transform: translate3d(140px, 0, 0);
  }
}

@media all and (min-width: 2200px) {
  html.mm-opening.mm-columns-4 .mm-slideout {
    -webkit-transform: translate(1760px, 0);
    -ms-transform: translate(1760px, 0);
    transform: translate(1760px, 0);
    -webkit-transform: translate3d(1760px, 0, 0);
    transform: translate3d(1760px, 0, 0);
  }
}

html.mm-right.mm-opening.mm-columns-4 .mm-slideout {
  -webkit-transform: translate(-80%, 0);
  -ms-transform: translate(-80%, 0);
  transform: translate(-80%, 0);
  -webkit-transform: translate3d(-80%, 0, 0);
  transform: translate3d(-80%, 0, 0);
}

@media all and (max-width: 175px) {
  html.mm-right.mm-opening.mm-columns-4 .mm-slideout {
    -webkit-transform: translate(-140px, 0);
    -ms-transform: translate(-140px, 0);
    transform: translate(-140px, 0);
    -webkit-transform: translate3d(-140px, 0, 0);
    transform: translate3d(-140px, 0, 0);
  }
}

@media all and (min-width: 2200px) {
  html.mm-right.mm-opening.mm-columns-4 .mm-slideout {
    -webkit-transform: translate(-1760px, 0);
    -ms-transform: translate(-1760px, 0);
    transform: translate(-1760px, 0);
    -webkit-transform: translate3d(-1760px, 0, 0);
    transform: translate3d(-1760px, 0, 0);
  }
}

.mm-menu.mm-columns-4 .mm-panels > .mm-panel {
  width: 25%;
}

.mm-menu.mm-columns .mm-panels > .mm-panel.mm-columns-5 {
  -webkit-transform: translate(500%, 0);
  -ms-transform: translate(500%, 0);
  transform: translate(500%, 0);
  -webkit-transform: translate3d(500%, 0, 0);
  transform: translate3d(500%, 0, 0);
}

.mm-menu.mm-columns .mm-panels > .mm-panel.mm-opened:not([class*=mm-columns-]) ~ .mm-panel:not(.mm-opened).mm-columns-5 {
  display: block;
  -webkit-transform: translate(600%, 0);
  -ms-transform: translate(600%, 0);
  transform: translate(600%, 0);
  -webkit-transform: translate3d(600%, 0, 0);
  transform: translate3d(600%, 0, 0);
}

.mm-menu.mm-offcanvas.mm-columns-5 {
  width: 80%;
  min-width: 140px;
  max-width: 2200px;
}

html.mm-opening.mm-columns-5 .mm-slideout {
  -webkit-transform: translate(80%, 0);
  -ms-transform: translate(80%, 0);
  transform: translate(80%, 0);
  -webkit-transform: translate3d(80%, 0, 0);
  transform: translate3d(80%, 0, 0);
}

@media all and (max-width: 175px) {
  html.mm-opening.mm-columns-5 .mm-slideout {
    -webkit-transform: translate(140px, 0);
    -ms-transform: translate(140px, 0);
    transform: translate(140px, 0);
    -webkit-transform: translate3d(140px, 0, 0);
    transform: translate3d(140px, 0, 0);
  }
}

@media all and (min-width: 2750px) {
  html.mm-opening.mm-columns-5 .mm-slideout {
    -webkit-transform: translate(2200px, 0);
    -ms-transform: translate(2200px, 0);
    transform: translate(2200px, 0);
    -webkit-transform: translate3d(2200px, 0, 0);
    transform: translate3d(2200px, 0, 0);
  }
}

html.mm-right.mm-opening.mm-columns-5 .mm-slideout {
  -webkit-transform: translate(-80%, 0);
  -ms-transform: translate(-80%, 0);
  transform: translate(-80%, 0);
  -webkit-transform: translate3d(-80%, 0, 0);
  transform: translate3d(-80%, 0, 0);
}

@media all and (max-width: 175px) {
  html.mm-right.mm-opening.mm-columns-5 .mm-slideout {
    -webkit-transform: translate(-140px, 0);
    -ms-transform: translate(-140px, 0);
    transform: translate(-140px, 0);
    -webkit-transform: translate3d(-140px, 0, 0);
    transform: translate3d(-140px, 0, 0);
  }
}

@media all and (min-width: 2750px) {
  html.mm-right.mm-opening.mm-columns-5 .mm-slideout {
    -webkit-transform: translate(-2200px, 0);
    -ms-transform: translate(-2200px, 0);
    transform: translate(-2200px, 0);
    -webkit-transform: translate3d(-2200px, 0, 0);
    transform: translate3d(-2200px, 0, 0);
  }
}

.mm-menu.mm-columns-5 .mm-panels > .mm-panel {
  width: 20%;
}

.mm-menu.mm-columns .mm-panels > .mm-panel.mm-columns-6 {
  -webkit-transform: translate(600%, 0);
  -ms-transform: translate(600%, 0);
  transform: translate(600%, 0);
  -webkit-transform: translate3d(600%, 0, 0);
  transform: translate3d(600%, 0, 0);
}

.mm-menu.mm-columns .mm-panels > .mm-panel.mm-opened:not([class*=mm-columns-]) ~ .mm-panel:not(.mm-opened).mm-columns-6 {
  display: block;
  -webkit-transform: translate(700%, 0);
  -ms-transform: translate(700%, 0);
  transform: translate(700%, 0);
  -webkit-transform: translate3d(700%, 0, 0);
  transform: translate3d(700%, 0, 0);
}

.mm-menu.mm-offcanvas.mm-columns-6 {
  width: 80%;
  min-width: 140px;
  max-width: 2640px;
}

html.mm-opening.mm-columns-6 .mm-slideout {
  -webkit-transform: translate(80%, 0);
  -ms-transform: translate(80%, 0);
  transform: translate(80%, 0);
  -webkit-transform: translate3d(80%, 0, 0);
  transform: translate3d(80%, 0, 0);
}

@media all and (max-width: 175px) {
  html.mm-opening.mm-columns-6 .mm-slideout {
    -webkit-transform: translate(140px, 0);
    -ms-transform: translate(140px, 0);
    transform: translate(140px, 0);
    -webkit-transform: translate3d(140px, 0, 0);
    transform: translate3d(140px, 0, 0);
  }
}

@media all and (min-width: 3300px) {
  html.mm-opening.mm-columns-6 .mm-slideout {
    -webkit-transform: translate(2640px, 0);
    -ms-transform: translate(2640px, 0);
    transform: translate(2640px, 0);
    -webkit-transform: translate3d(2640px, 0, 0);
    transform: translate3d(2640px, 0, 0);
  }
}

html.mm-right.mm-opening.mm-columns-6 .mm-slideout {
  -webkit-transform: translate(-80%, 0);
  -ms-transform: translate(-80%, 0);
  transform: translate(-80%, 0);
  -webkit-transform: translate3d(-80%, 0, 0);
  transform: translate3d(-80%, 0, 0);
}

@media all and (max-width: 175px) {
  html.mm-right.mm-opening.mm-columns-6 .mm-slideout {
    -webkit-transform: translate(-140px, 0);
    -ms-transform: translate(-140px, 0);
    transform: translate(-140px, 0);
    -webkit-transform: translate3d(-140px, 0, 0);
    transform: translate3d(-140px, 0, 0);
  }
}

@media all and (min-width: 3300px) {
  html.mm-right.mm-opening.mm-columns-6 .mm-slideout {
    -webkit-transform: translate(-2640px, 0);
    -ms-transform: translate(-2640px, 0);
    transform: translate(-2640px, 0);
    -webkit-transform: translate3d(-2640px, 0, 0);
    transform: translate3d(-2640px, 0, 0);
  }
}

.mm-menu.mm-columns-6 .mm-panels > .mm-panel {
  width: 16.67%;
}

em.mm-counter {
  font: inherit;
  font-size: 14px;
  font-style: normal;
  text-indent: 0;
  line-height: 20px;
  display: block;
  margin-top: -10px;
  position: absolute;
  right: 45px;
  top: 50%;
}

em.mm-counter + a.mm-next {
  width: 90px;
}

em.mm-counter + a.mm-next + a, em.mm-counter + a.mm-next + span {
  margin-right: 90px;
}

em.mm-counter + a.mm-fullsubopen {
  padding-left: 0;
}

.mm-listview em.mm-counter + .mm-next.mm-fullsubopen + a, .mm-listview em.mm-counter + .mm-next.mm-fullsubopen + span, em.mm-counter + a.mm-fullsubopen + a, em.mm-counter + a.mm-fullsubopen + span {
  padding-right: 90px;
}

.mm-vertical > .mm-counter {
  top: 12px;
  margin-top: 0;
}

.mm-vertical.mm-spacer > .mm-counter {
  margin-top: 40px;
}

.mm-nosubresults > .mm-counter {
  display: none;
}

.mm-menu em.mm-counter {
  color: rgba(0, 0, 0, 0.3);
}

.mm-divider > span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0;
  line-height: 25px;
}

.mm-divider.mm-opened a.mm-next:after {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.mm-collapsed:not(.mm-uncollapsed) {
  display: none;
}

.mm-fixeddivider {
  background: inherit;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mm-fixeddivider:after {
  content: none !important;
  display: none !important;
}

.mm-hasdividers .mm-fixeddivider {
  display: block;
}

.mm-menu .mm-fixeddivider span {
  background: rgba(0, 0, 0, 0.05);
}

html.mm-opened.mm-dragging .mm-menu, html.mm-opened.mm-dragging .mm-slideout {
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
}

.mm-menu.mm-dropdown {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  height: 80%;
}

html.mm-dropdown .mm-slideout {
  -webkit-transform: none !important;
  -ms-transform: none !important;
  transform: none !important;
  z-index: 0;
}

html.mm-dropdown #mm-blocker {
  -webkit-transition-delay: 0s !important;
  transition-delay: 0s !important;
  z-index: 1;
}

html.mm-dropdown .mm-menu {
  z-index: 2;
}

html.mm-dropdown.mm-opened:not(.mm-opening) .mm-menu {
  display: none;
}

.mm-menu.mm-tip:before {
  content: '';
  background: inherit;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  display: block;
  width: 15px;
  height: 15px;
  position: absolute;
  z-index: 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.mm-menu.mm-tipleft:before {
  left: 22px;
}

.mm-menu.mm-tipright:before {
  right: 22px;
}

.mm-menu.mm-tiptop:before {
  top: -8px;
}

.mm-menu.mm-tipbottom:before {
  bottom: -8px;
}

.mm-iconpanel .mm-panel {
  -webkit-transition-property: left,-webkit-transform;
  transition-property: left,-webkit-transform;
  transition-property: transform,left;
  transition-property: transform,left,-webkit-transform;
}

.mm-iconpanel .mm-panel.mm-opened {
  border-left: 1px solid;
  border-color: inherit;
}

.mm-iconpanel .mm-panel.mm-subopened {
  overflow-y: hidden;
  left: -40px;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mm-iconpanel .mm-panel.mm-iconpanel-0 {
  left: 0;
}

.mm-iconpanel .mm-panel.mm-iconpanel-1 {
  left: 40px;
}

.mm-iconpanel .mm-panel.mm-iconpanel-2 {
  left: 80px;
}

.mm-iconpanel .mm-panel.mm-iconpanel-3 {
  left: 120px;
}

.mm-iconpanel .mm-panel.mm-iconpanel-4 {
  left: 160px;
}

.mm-iconpanel .mm-panel.mm-iconpanel-5 {
  left: 200px;
}

.mm-iconpanel .mm-panel.mm-iconpanel-6 {
  left: 240px;
}

.mm-subblocker {
  background: inherit;
  opacity: 0;
  display: block;
  -webkit-transition: opacity .4s ease;
  transition: opacity .4s ease;
}

.mm-subopened .mm-subblocker {
  opacity: .6;
  position: absolute;
  top: 0;
  right: 0;
  bottom: -100000px;
  left: 0;
  z-index: 3;
}

.mm-keyboardfocus a:focus {
  outline: 0;
}

.mm-menu.mm-keyboardfocus a:focus {
  background: rgba(255, 255, 255, 0.5);
}

.mm-navbar-bottom, .mm-navbar-top ~ .mm-navbar-top {
  border-bottom: none;
}

.mm-menu > .mm-navbar {
  background: inherit;
  padding: 0;
  z-index: 3;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mm-navbar-bottom {
  border-top-width: 1px;
  border-top-style: solid;
  top: auto;
  bottom: 0;
}

.mm-navbar-bottom ~ .mm-navbar-bottom {
  border-top: none;
}

.mm-navbar.mm-hasbtns {
  padding: 0 40px;
}

.mm-navbar[class*=mm-navbar-content-] > * {
  box-sizing: border-box;
  display: block;
  float: left;
}

.mm-navbar .mm-breadcrumbs {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  text-align: left;
  padding: 0 0 0 17px;
}

.mm-navbar .mm-breadcrumbs > * {
  display: inline-block;
  padding: 10px 3px;
}

.mm-navbar .mm-breadcrumbs > a {
  text-decoration: underline;
}

.mm-navbar.mm-hasbtns .mm-breadcrumbs {
  margin-left: -40px;
}

.mm-navbar.mm-hasbtns .mm-btn:not(.mm-hidden) + .mm-breadcrumbs {
  margin-left: 0;
  padding-left: 0;
}

.mm-navbar-top-1 {
  top: 0;
}

.mm-hasnavbar-top-1 .mm-panels, .mm-navbar-top-2 {
  top: 40px;
}

.mm-hasnavbar-top-2 .mm-panels, .mm-navbar-top-3 {
  top: 80px;
}

.mm-hasnavbar-top-3 .mm-panels, .mm-navbar-top-4 {
  top: 120px;
}

.mm-hasnavbar-top-4 .mm-panels {
  top: 160px;
}

.mm-navbar-bottom-1 {
  bottom: 0;
}

.mm-hasnavbar-bottom-1 .mm-panels, .mm-navbar-bottom-2 {
  bottom: 40px;
}

.mm-hasnavbar-bottom-2 .mm-panels, .mm-navbar-bottom-3 {
  bottom: 80px;
}

.mm-hasnavbar-bottom-3 .mm-panels, .mm-navbar-bottom-4 {
  bottom: 120px;
}

.mm-hasnavbar-bottom-4 .mm-panels {
  bottom: 160px;
}

.mm-navbar-size-2 {
  height: 80px;
}

.mm-navbar-size-3 {
  height: 120px;
}

.mm-navbar-size-4 {
  height: 160px;
}

.mm-navbar-content-2 > * {
  width: 50%;
}

.mm-navbar-content-3 > * {
  width: 33.33%;
}

.mm-navbar-content-4 > * {
  width: 25%;
}

.mm-navbar-content-5 > * {
  width: 20%;
}

.mm-navbar-content-6 > * {
  width: 16.67%;
}

.mm-menu.mm-rtl {
  direction: rtl;
}

.mm-menu.mm-rtl.mm-offcanvas {
  right: auto;
}

.mm-menu.mm-rtl .mm-panel:not(.mm-opened) {
  -webkit-transform: translate(-100%, 0);
  -ms-transform: translate(-100%, 0);
  transform: translate(-100%, 0);
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

.mm-menu.mm-rtl .mm-panel.mm-subopened {
  -webkit-transform: translate(30%, 0);
  -ms-transform: translate(30%, 0);
  transform: translate(30%, 0);
  -webkit-transform: translate3d(30%, 0, 0);
  transform: translate3d(30%, 0, 0);
}

.mm-menu.mm-rtl .mm-navbar .mm-btn:first-child {
  left: auto;
  right: 0;
}

.mm-menu.mm-rtl .mm-navbar .mm-btn:last-child {
  right: auto;
  left: 0;
}

.mm-menu.mm-rtl .mm-navbar .mm-next:after {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  left: 23px;
  right: auto;
}

.mm-menu.mm-rtl .mm-navbar .mm-prev:before {
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
  right: 23px;
  left: auto;
}

.mm-menu.mm-rtl .mm-listview > li:not(.mm-divider)::after {
  left: 0;
  right: 20px;
}

.mm-menu.mm-rtl .mm-listview > li > a:not(.mm-next), .mm-menu.mm-rtl .mm-listview > li > span:not(.mm-next) {
  padding-left: 10px;
  padding-right: 20px !important;
  margin-right: 0 !important;
}

.mm-menu.mm-rtl .mm-listview .mm-next {
  right: auto;
  left: 0;
}

.mm-menu.mm-rtl .mm-listview .mm-next:before {
  left: auto;
  right: 0;
}

.mm-menu.mm-rtl .mm-listview .mm-next:after {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  left: 23px;
  right: auto;
}

.mm-menu.mm-rtl .mm-listview .mm-next + a, .mm-menu.mm-rtl .mm-listview .mm-next + span {
  margin-left: 50px;
}

.mm-menu.mm-rtl .mm-listview .mm-next.mm-fullsubopen + a, .mm-menu.mm-rtl .mm-listview .mm-next.mm-fullsubopen + span {
  padding-left: 50px;
}

.mm-menu.mm-rtl em.mm-counter {
  left: 45px;
  right: auto;
}

.mm-menu.mm-rtl em.mm-counter + a.mm-next + a, .mm-menu.mm-rtl em.mm-counter + a.mm-next + span {
  margin-left: 90px;
}

.mm-menu.mm-rtl .mm-listview em.mm-counter + .mm-fullsubopen + a, .mm-menu.mm-rtl .mm-listview em.mm-counter + .mm-fullsubopen + span {
  padding-left: 90px;
}

.mm-menu.mm-rtl label.mm-check, .mm-menu.mm-rtl label.mm-toggle {
  left: 20px;
  right: auto !important;
}

.mm-menu.mm-rtl label.mm-toggle + a, .mm-menu.mm-rtl label.mm-toggle + span {
  padding-left: 80px;
}

.mm-menu.mm-rtl label.mm-check + a, .mm-menu.mm-rtl label.mm-check + span {
  padding-left: 60px;
}

.mm-menu.mm-rtl a.mm-next + label.mm-check, .mm-menu.mm-rtl a.mm-next + label.mm-toggle {
  left: 60px;
}

.mm-menu.mm-rtl a.mm-next + label.mm-check + a, .mm-menu.mm-rtl a.mm-next + label.mm-check + span, .mm-menu.mm-rtl a.mm-next + label.mm-toggle + a, .mm-menu.mm-rtl a.mm-next + label.mm-toggle + span {
  margin-left: 50px;
}

.mm-menu.mm-rtl a.mm-next + label.mm-toggle + a, .mm-menu.mm-rtl a.mm-next + label.mm-toggle + span {
  padding-left: 70px;
}

.mm-menu.mm-rtl a.mm-next + label.mm-check + a, .mm-menu.mm-rtl a.mm-next + label.mm-check + span {
  padding-left: 50px;
}

.mm-menu.mm-rtl em.mm-counter + a.mm-next + label.mm-check, .mm-menu.mm-rtl em.mm-counter + a.mm-next + label.mm-toggle {
  left: 100px;
}

.mm-menu.mm-rtl em.mm-counter + a.mm-next + label.mm-check + a, .mm-menu.mm-rtl em.mm-counter + a.mm-next + label.mm-check + span, .mm-menu.mm-rtl em.mm-counter + a.mm-next + label.mm-toggle + a, .mm-menu.mm-rtl em.mm-counter + a.mm-next + label.mm-toggle + span {
  margin-left: 90px;
}

.mm-menu.mm-rtl .mm-panel[class*=mm-iconpanel-] {
  left: 0;
}

.mm-menu.mm-rtl .mm-panel[class*=mm-iconpanel-].mm-subopened {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mm-menu.mm-rtl.mm-iconpanel .mm-panel {
  -webkit-transition-property: right,-webkit-transform;
  transition-property: right,-webkit-transform;
  transition-property: transform,right;
  transition-property: transform,right,-webkit-transform;
}

.mm-menu.mm-rtl.mm-iconpanel .mm-panel.mm-iconpanel-0 {
  right: 0;
}

.mm-menu.mm-rtl.mm-iconpanel .mm-panel.mm-iconpanel-1 {
  right: 40px;
}

.mm-menu.mm-rtl.mm-iconpanel .mm-panel.mm-iconpanel-2 {
  right: 80px;
}

.mm-menu.mm-rtl.mm-iconpanel .mm-panel.mm-iconpanel-3 {
  right: 120px;
}

.mm-menu.mm-rtl.mm-iconpanel .mm-panel.mm-iconpanel-4 {
  right: 160px;
}

.mm-menu.mm-rtl.mm-iconpanel .mm-panel.mm-iconpanel-5 {
  right: 200px;
}

.mm-menu.mm-rtl.mm-iconpanel .mm-panel.mm-iconpanel-6 {
  right: 240px;
}

.mm-menu.mm-rtl.mm-iconpanel .mm-panel.mm-opened {
  border-left: none;
  border-right: 1px solid;
  border-color: inherit;
}

.mm-sronly {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  position: absolute !important;
}

.mm-search, .mm-search input {
  box-sizing: border-box;
}

.mm-search {
  height: 40px;
  padding: 7px 10px 0;
  position: relative;
}

.mm-search input {
  border: none !important;
  outline: 0 !important;
  box-shadow: none !important;
  border-radius: 4px;
  font: inherit;
  font-size: 14px;
  line-height: 26px;
  display: block;
  width: 100%;
  height: 26px;
  min-height: 26px;
  max-height: 26px;
  margin: 0;
  padding: 0 10px;
}

.mm-search input::-ms-clear {
  display: none;
}

.mm-search .mm-clear, .mm-search .mm-next {
  right: 0;
}

.mm-panel > .mm-search {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.mm-panel.mm-hassearch {
  padding-top: 40px;
}

.mm-panel.mm-hassearch.mm-hasnavbar {
  padding-top: 80px;
}

.mm-panel.mm-hassearch.mm-hasnavbar .mm-search {
  top: 40px;
}

.mm-noresultsmsg {
  text-align: center;
  font-size: 21px;
  padding: 40px 0;
}

.mm-noresults .mm-indexer {
  display: none !important;
}

li.mm-nosubresults > a.mm-next {
  display: none;
}

li.mm-nosubresults > a.mm-next + a, li.mm-nosubresults > a.mm-next + span {
  padding-right: 10px;
}

.mm-panels > .mm-resultspanel:not(.mm-opened) {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mm-menu .mm-search input {
  background: rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.75);
}

.mm-menu .mm-noresultsmsg {
  color: rgba(0, 0, 0, 0.3);
}

.mm-indexer {
  background: inherit;
  text-align: center;
  font-size: 12px;
  box-sizing: border-box;
  width: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -100px;
  z-index: 3;
  -webkit-transition: right .4s ease;
  transition: right .4s ease;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mm-indexer a {
  text-decoration: none;
  display: block;
  height: 3.85%;
}

.mm-indexer ~ .mm-panel.mm-hasindexer {
  padding-right: 40px;
}

.mm-hasindexer .mm-indexer {
  right: 0;
}

.mm-hasindexer .mm-fixeddivider {
  right: 20px;
}

.mm-menu .mm-indexer a {
  color: rgba(0, 0, 0, 0.3);
}

.mm-menu.mm-hoverselected .mm-listview > li > a.mm-fullsubopen + a, .mm-menu.mm-hoverselected .mm-listview > li > a.mm-fullsubopen + span, .mm-menu.mm-hoverselected .mm-listview > li > a:not(.mm-fullsubopen), .mm-menu.mm-parentselected .mm-listview > li > a.mm-fullsubopen + a, .mm-menu.mm-parentselected .mm-listview > li > a.mm-fullsubopen + span, .mm-menu.mm-parentselected .mm-listview > li > a:not(.mm-fullsubopen) {
  -webkit-transition: background .4s ease;
  transition: background .4s ease;
}

.mm-menu.mm-hoverselected .mm-listview > li > a.mm-fullsubopen:hover + span, .mm-menu.mm-hoverselected .mm-listview > li > a:not(.mm-fullsubopen):hover, .mm-menu.mm-parentselected .mm-listview > li > a.mm-selected.mm-fullsubopen + a, .mm-menu.mm-parentselected .mm-listview > li > a.mm-selected.mm-fullsubopen + span, .mm-menu.mm-parentselected .mm-listview > li > a.mm-selected:not(.mm-fullsubopen) {
  background: rgba(255, 255, 255, 0.5);
}

input.mm-check, input.mm-toggle {
  position: absolute;
  left: -10000px;
}

label.mm-check, label.mm-toggle {
  margin: 0;
  position: absolute;
  top: 50%;
  z-index: 2;
  right: 20px;
}

label.mm-check:before, label.mm-toggle:before {
  content: '';
  display: block;
}

label.mm-toggle {
  border-radius: 30px;
  width: 50px;
  height: 30px;
  margin-top: -15px;
}

label.mm-toggle:before {
  border-radius: 30px;
  width: 28px;
  height: 28px;
  margin: 1px;
}

input.mm-toggle:checked ~ label.mm-toggle:before {
  float: right;
}

label.mm-check {
  width: 30px;
  height: 30px;
  margin-top: -15px;
}

label.mm-check:before {
  border-left: 3px solid;
  border-bottom: 3px solid;
  width: 40%;
  height: 20%;
  margin: 25% 0 0 20%;
  opacity: .1;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

input.mm-check:checked ~ label.mm-check:before {
  opacity: 1;
}

li.mm-vertical label.mm-check, li.mm-vertical label.mm-toggle {
  bottom: auto;
  margin-top: 0;
  top: 5px;
}

label.mm-toggle + a, label.mm-toggle + span {
  padding-right: 80px;
}

label.mm-check + a, label.mm-check + span {
  padding-right: 60px;
}

a.mm-next + label.mm-check, a.mm-next + label.mm-toggle {
  right: 60px;
}

a.mm-next + label.mm-check + a, a.mm-next + label.mm-check + span, a.mm-next + label.mm-toggle + a, a.mm-next + label.mm-toggle + span {
  margin-right: 50px;
}

a.mm-next + label.mm-toggle + a, a.mm-next + label.mm-toggle + span {
  padding-right: 70px;
}

a.mm-next + label.mm-check + a, a.mm-next + label.mm-check + span {
  padding-right: 50px;
}

em.mm-counter + a.mm-next + label.mm-check, em.mm-counter + a.mm-next + label.mm-toggle {
  right: 100px;
}

em.mm-counter + a.mm-next + label.mm-check + a, em.mm-counter + a.mm-next + label.mm-check + span, em.mm-counter + a.mm-next + label.mm-toggle + a, em.mm-counter + a.mm-next + label.mm-toggle + span {
  margin-right: 90px;
}

.mm-menu label.mm-toggle {
  background: rgba(0, 0, 0, 0.1);
}

.mm-menu label.mm-toggle:before {
  background: #f3f3f3;
}

.mm-menu input.mm-toggle:checked ~ label.mm-toggle {
  background: #4bd963;
}

.mm-menu label.mm-check:before {
  border-color: rgba(0, 0, 0, 0.75);
}

.mm-listview.mm-border-none > li:after, .mm-menu.mm-border-none .mm-listview > li:after {
  content: none;
}

.mm-listview.mm-border-full > li:after, .mm-menu.mm-border-full .mm-listview > li:after {
  left: 0 !important;
}

.mm-listview.mm-border-offset > li:after, .mm-menu.mm-border-offset .mm-listview > li:after {
  right: 20px;
}

.mm-menu.mm-effect-menu-zoom {
  -webkit-transition: -webkit-transform .4s ease;
  transition: -webkit-transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease,-webkit-transform .4s ease;
}

html.mm-opened .mm-menu.mm-effect-menu-zoom {
  -webkit-transform: scale(0.7, 0.7) translate3d(-30%, 0, 0);
  transform: scale(0.7, 0.7) translate3d(-30%, 0, 0);
  -webkit-transform-origin: left center;
  -ms-transform-origin: left center;
  transform-origin: left center;
}

html.mm-opening .mm-menu.mm-effect-menu-zoom {
  -webkit-transform: scale(1, 1) translate3d(0, 0, 0);
  transform: scale(1, 1) translate3d(0, 0, 0);
}

html.mm-right.mm-opened .mm-menu.mm-effect-menu-zoom {
  -webkit-transform: scale(0.7, 0.7) translate3d(30%, 0, 0);
  transform: scale(0.7, 0.7) translate3d(30%, 0, 0);
  -webkit-transform-origin: right center;
  -ms-transform-origin: right center;
  transform-origin: right center;
}

html.mm-right.mm-opening .mm-menu.mm-effect-menu-zoom {
  -webkit-transform: scale(1, 1) translate3d(0, 0, 0);
  transform: scale(1, 1) translate3d(0, 0, 0);
}

.mm-menu.mm-effect-menu-slide {
  -webkit-transition: -webkit-transform .4s ease;
  transition: -webkit-transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease,-webkit-transform .4s ease;
}

html.mm-opened .mm-menu.mm-effect-menu-slide {
  -webkit-transform: translate(-30%, 0);
  -ms-transform: translate(-30%, 0);
  transform: translate(-30%, 0);
  -webkit-transform: translate3d(-30%, 0, 0);
  transform: translate3d(-30%, 0, 0);
}

html.mm-opening .mm-menu.mm-effect-menu-slide {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

html.mm-right.mm-opened .mm-menu.mm-effect-menu-slide {
  -webkit-transform: translate(30%, 0);
  -ms-transform: translate(30%, 0);
  transform: translate(30%, 0);
  -webkit-transform: translate3d(30%, 0, 0);
  transform: translate3d(30%, 0, 0);
}

html.mm-right.mm-opening .mm-menu.mm-effect-menu-slide {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mm-menu.mm-effect-menu-fade {
  opacity: 0;
  -webkit-transition: opacity .4s ease;
  transition: opacity .4s ease;
}

html.mm-opening .mm-menu.mm-effect-menu-fade {
  opacity: 1;
}

.mm-menu.mm-effect-panels-zoom .mm-panel {
  -webkit-transform: scale(1.5, 1.5) translate3d(100%, 0, 0);
  transform: scale(1.5, 1.5) translate3d(100%, 0, 0);
  -webkit-transform-origin: left center;
  -ms-transform-origin: left center;
  transform-origin: left center;
}

.mm-menu.mm-effect-panels-zoom .mm-panel.mm-opened {
  -webkit-transform: scale(1, 1) translate3d(0, 0, 0);
  transform: scale(1, 1) translate3d(0, 0, 0);
}

.mm-menu.mm-effect-panels-zoom .mm-panel.mm-opened.mm-subopened {
  -webkit-transform: scale(0.7, 0.7) translate3d(-30%, 0, 0);
  transform: scale(0.7, 0.7) translate3d(-30%, 0, 0);
}

.mm-menu.mm-effect-panels-slide-0 .mm-panel.mm-subopened {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mm-menu.mm-effect-panels-slide-100 .mm-panel.mm-subopened {
  -webkit-transform: translate(-100%, 0);
  -ms-transform: translate(-100%, 0);
  transform: translate(-100%, 0);
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

.mm-menu[class*=mm-effect-listitems-] .mm-listview > li {
  -webkit-transition: none .4s ease;
  transition: none .4s ease;
}

.mm-menu[class*=mm-effect-listitems-] .mm-listview > li:nth-child(1) {
  -webkit-transition-delay: 50ms;
  transition-delay: 50ms;
}

.mm-menu[class*=mm-effect-listitems-] .mm-listview > li:nth-child(2) {
  -webkit-transition-delay: .1s;
  transition-delay: .1s;
}

.mm-menu[class*=mm-effect-listitems-] .mm-listview > li:nth-child(3) {
  -webkit-transition-delay: 150ms;
  transition-delay: 150ms;
}

.mm-menu[class*=mm-effect-listitems-] .mm-listview > li:nth-child(4) {
  -webkit-transition-delay: .2s;
  transition-delay: .2s;
}

.mm-menu[class*=mm-effect-listitems-] .mm-listview > li:nth-child(5) {
  -webkit-transition-delay: 250ms;
  transition-delay: 250ms;
}

.mm-menu[class*=mm-effect-listitems-] .mm-listview > li:nth-child(6) {
  -webkit-transition-delay: .3s;
  transition-delay: .3s;
}

.mm-menu[class*=mm-effect-listitems-] .mm-listview > li:nth-child(7) {
  -webkit-transition-delay: 350ms;
  transition-delay: 350ms;
}

.mm-menu[class*=mm-effect-listitems-] .mm-listview > li:nth-child(8) {
  -webkit-transition-delay: .4s;
  transition-delay: .4s;
}

.mm-menu[class*=mm-effect-listitems-] .mm-listview > li:nth-child(9) {
  -webkit-transition-delay: 450ms;
  transition-delay: 450ms;
}

.mm-menu[class*=mm-effect-listitems-] .mm-listview > li:nth-child(10) {
  -webkit-transition-delay: .5s;
  transition-delay: .5s;
}

.mm-menu[class*=mm-effect-listitems-] .mm-listview > li:nth-child(11) {
  -webkit-transition-delay: 550ms;
  transition-delay: 550ms;
}

.mm-menu[class*=mm-effect-listitems-] .mm-listview > li:nth-child(12) {
  -webkit-transition-delay: .6s;
  transition-delay: .6s;
}

.mm-menu[class*=mm-effect-listitems-] .mm-listview > li:nth-child(13) {
  -webkit-transition-delay: 650ms;
  transition-delay: 650ms;
}

.mm-menu[class*=mm-effect-listitems-] .mm-listview > li:nth-child(14) {
  -webkit-transition-delay: .7s;
  transition-delay: .7s;
}

.mm-menu[class*=mm-effect-listitems-] .mm-listview > li:nth-child(15) {
  -webkit-transition-delay: 750ms;
  transition-delay: 750ms;
}

.mm-menu.mm-effect-listitems-slide .mm-listview > li {
  -webkit-transition-property: opacity,-webkit-transform;
  transition-property: opacity,-webkit-transform;
  transition-property: transform,opacity;
  transition-property: transform,opacity,-webkit-transform;
  -webkit-transform: translate(50%, 0);
  -ms-transform: translate(50%, 0);
  transform: translate(50%, 0);
  -webkit-transform: translate3d(50%, 0, 0);
  transform: translate3d(50%, 0, 0);
  opacity: 0;
}

html.mm-opening .mm-menu.mm-effect-listitems-slide .mm-panel.mm-opened .mm-listview > li {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.mm-menu.mm-effect-listitems-fade .mm-listview > li {
  -webkit-transition-property: opacity;
  transition-property: opacity;
  opacity: 0;
}

html.mm-opening .mm-menu.mm-effect-listitems-fade .mm-panel.mm-opened .mm-listview > li {
  opacity: 1;
}

.mm-menu.mm-effect-listitems-drop .mm-listview > li {
  -webkit-transition-property: opacity,top;
  transition-property: opacity,top;
  opacity: 0;
  top: -25%;
}

html.mm-opening .mm-menu.mm-effect-listitems-drop .mm-panel.mm-opened .mm-listview > li {
  opacity: 1;
  top: 0;
}

.mm-menu.mm-offcanvas.mm-fullscreen {
  width: 100%;
  min-width: 140px;
  max-width: 10000px;
}

html.mm-opening.mm-fullscreen .mm-slideout {
  -webkit-transform: translate(100%, 0);
  -ms-transform: translate(100%, 0);
  transform: translate(100%, 0);
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

@media all and (max-width: 140px) {
  html.mm-opening.mm-fullscreen .mm-slideout {
    -webkit-transform: translate(140px, 0);
    -ms-transform: translate(140px, 0);
    transform: translate(140px, 0);
    -webkit-transform: translate3d(140px, 0, 0);
    transform: translate3d(140px, 0, 0);
  }
}

@media all and (min-width: 10000px) {
  html.mm-opening.mm-fullscreen .mm-slideout {
    -webkit-transform: translate(10000px, 0);
    -ms-transform: translate(10000px, 0);
    transform: translate(10000px, 0);
    -webkit-transform: translate3d(10000px, 0, 0);
    transform: translate3d(10000px, 0, 0);
  }
}

html.mm-right.mm-opening.mm-fullscreen .mm-slideout {
  -webkit-transform: translate(-100%, 0);
  -ms-transform: translate(-100%, 0);
  transform: translate(-100%, 0);
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

@media all and (max-width: 140px) {
  html.mm-right.mm-opening.mm-fullscreen .mm-slideout {
    -webkit-transform: translate(-140px, 0);
    -ms-transform: translate(-140px, 0);
    transform: translate(-140px, 0);
    -webkit-transform: translate3d(-140px, 0, 0);
    transform: translate3d(-140px, 0, 0);
  }
}

@media all and (min-width: 10000px) {
  html.mm-right.mm-opening.mm-fullscreen .mm-slideout {
    -webkit-transform: translate(-10000px, 0);
    -ms-transform: translate(-10000px, 0);
    transform: translate(-10000px, 0);
    -webkit-transform: translate3d(-10000px, 0, 0);
    transform: translate3d(-10000px, 0, 0);
  }
}

.mm-menu.mm-offcanvas.mm-fullscreen.mm-bottom, .mm-menu.mm-offcanvas.mm-fullscreen.mm-top {
  height: 100%;
  min-height: 140px;
  max-height: 10000px;
}

html.mm-opened.mm-fullscreen .mm-page {
  box-shadow: none !important;
}

.mm-menu .mm-panels > .mm-panel.mm-justified-listview:after, .mm-menu .mm-panels > .mm-panel.mm-justified-listview:before, .mm-menu.mm-justified-listview .mm-panels > .mm-panel:after, .mm-menu.mm-justified-listview .mm-panels > .mm-panel:before {
  content: none;
  display: none;
}

.mm-menu .mm-panels > .mm-panel.mm-justified-listview > .mm-listview, .mm-menu.mm-justified-listview .mm-panels > .mm-panel > .mm-listview {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: column nowrap;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  height: 100%;
  margin-top: 0;
  margin-bottom: 0;
}

.mm-menu .mm-panels > .mm-panel.mm-justified-listview > .mm-listview > li:not(.mm-divider), .mm-menu.mm-justified-listview .mm-panels > .mm-panel > .mm-listview > li:not(.mm-divider) {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 0;
  -ms-flex: 1 1 0;
  flex: 1 1 0;
}

.mm-menu .mm-panels > .mm-panel.mm-justified-listview > .mm-listview > li:not(.mm-divider) > a:not(.mm-next), .mm-menu .mm-panels > .mm-panel.mm-justified-listview > .mm-listview > li:not(.mm-divider) > span, .mm-menu.mm-justified-listview .mm-panels > .mm-panel > .mm-listview > li:not(.mm-divider) > a:not(.mm-next), .mm-menu.mm-justified-listview .mm-panels > .mm-panel > .mm-listview > li:not(.mm-divider) > span {
  box-sizing: border-box;
  height: 100%;
}

.mm-menu .mm-panels > .mm-panel.mm-justified-listview > .mm-listview > li:not(.mm-divider) > a:not(.mm-next):before, .mm-menu .mm-panels > .mm-panel.mm-justified-listview > .mm-listview > li:not(.mm-divider) > span:before, .mm-menu.mm-justified-listview .mm-panels > .mm-panel > .mm-listview > li:not(.mm-divider) > a:not(.mm-next):before, .mm-menu.mm-justified-listview .mm-panels > .mm-panel > .mm-listview > li:not(.mm-divider) > span:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  height: 100%;
  width: 0;
}

.mm-listview.mm-multiline > li .mm-listview > li.mm-multiline > a, .mm-listview.mm-multiline > li .mm-listview > li.mm-multiline > span, .mm-menu.mm-multiline .mm-listview > li > a, .mm-menu.mm-multiline .mm-listview > li > span {
  text-overflow: clip;
  white-space: normal;
}

html.mm-pagedim #mm-blocker, html.mm-pagedim-black #mm-blocker, html.mm-pagedim-white #mm-blocker {
  opacity: 0;
}

html.mm-pagedim-black.mm-opening #mm-blocker, html.mm-pagedim-white.mm-opening #mm-blocker, html.mm-pagedim.mm-opening #mm-blocker {
  opacity: .3;
  -webkit-transition: opacity .4s ease .4s;
  transition: opacity .4s ease .4s;
}

html.mm-pagedim #mm-blocker {
  background: inherit;
}

html.mm-pagedim-white #mm-blocker {
  background: #fff;
}

html.mm-pagedim-black #mm-blocker {
  background: #000;
}

.mm-menu.mm-popup {
  -webkit-transition: opacity .4s ease;
  transition: opacity .4s ease;
  opacity: 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  height: 80%;
  min-height: 140px;
  max-height: 880px;
  top: 50%;
  left: 50%;
  bottom: auto;
  right: auto;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}

html.mm-popup.mm-opening .mm-menu.mm-popup {
  opacity: 1;
}

html.mm-popup .mm-slideout {
  -webkit-transform: none !important;
  -ms-transform: none !important;
  transform: none !important;
  z-index: 0;
}

html.mm-popup #mm-blocker {
  -webkit-transition-delay: 0s !important;
  transition-delay: 0s !important;
  z-index: 1;
}

html.mm-popup .mm-menu {
  z-index: 2;
}

.mm-menu.mm-offcanvas.mm-right {
  left: auto;
}

html.mm-right.mm-opening .mm-slideout {
  -webkit-transform: translate(-80%, 0);
  -ms-transform: translate(-80%, 0);
  transform: translate(-80%, 0);
  -webkit-transform: translate3d(-80%, 0, 0);
  transform: translate3d(-80%, 0, 0);
}

@media all and (max-width: 175px) {
  html.mm-right.mm-opening .mm-slideout {
    -webkit-transform: translate(-140px, 0);
    -ms-transform: translate(-140px, 0);
    transform: translate(-140px, 0);
    -webkit-transform: translate3d(-140px, 0, 0);
    transform: translate3d(-140px, 0, 0);
  }
}

@media all and (min-width: 550px) {
  html.mm-right.mm-opening .mm-slideout {
    -webkit-transform: translate(-440px, 0);
    -ms-transform: translate(-440px, 0);
    transform: translate(-440px, 0);
    -webkit-transform: translate3d(-440px, 0, 0);
    transform: translate3d(-440px, 0, 0);
  }
}

html.mm-front .mm-slideout {
  -webkit-transform: none !important;
  -ms-transform: none !important;
  transform: none !important;
  z-index: 0;
}

html.mm-front #mm-blocker {
  z-index: 1;
}

html.mm-front .mm-menu.mm-offcanvas {
  z-index: 2;
}

.mm-menu.mm-offcanvas.mm-front, .mm-menu.mm-offcanvas.mm-next {
  -webkit-transition: -webkit-transform .4s ease;
  transition: -webkit-transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease,-webkit-transform .4s ease;
  -webkit-transform: translate(-100%, 0);
  -ms-transform: translate(-100%, 0);
  transform: translate(-100%, 0);
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

.mm-menu.mm-offcanvas.mm-front.mm-right, .mm-menu.mm-offcanvas.mm-next.mm-right {
  -webkit-transform: translate(100%, 0);
  -ms-transform: translate(100%, 0);
  transform: translate(100%, 0);
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

.mm-menu.mm-offcanvas.mm-top {
  -webkit-transform: translate(0, -100%);
  -ms-transform: translate(0, -100%);
  transform: translate(0, -100%);
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
}

.mm-menu.mm-offcanvas.mm-bottom {
  -webkit-transform: translate(0, 100%);
  -ms-transform: translate(0, 100%);
  transform: translate(0, 100%);
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  top: auto;
}

.mm-menu.mm-offcanvas.mm-bottom, .mm-menu.mm-offcanvas.mm-top {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 80%;
  min-height: 140px;
  max-height: 880px;
}

html.mm-opening .mm-menu.mm-offcanvas.mm-front, html.mm-opening .mm-menu.mm-offcanvas.mm-next {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mm-menu.mm-shadow-page:after {
  content: "";
  display: block;
  width: 20px;
  height: 120%;
  position: absolute;
  left: 100%;
  top: -10%;
  z-index: 99;
}

.mm-menu.mm-shadow-page.mm-right:after {
  left: auto;
  right: 100%;
}

.mm-menu.mm-shadow-page.mm-front:after, .mm-menu.mm-shadow-page.mm-next:after {
  content: none;
  display: none;
}

.mm-menu.mm-shadow-page:after, .mm-menu.mm-shadow-panels .mm-panel.mm-opened:nth-child(n+2) {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.mm-menu.mm-theme-dark {
  background: #333;
  border-color: rgba(0, 0, 0, 0.15);
  color: rgba(255, 255, 255, 0.8);
}

.mm-menu.mm-theme-dark .mm-navbar a, .mm-menu.mm-theme-dark .mm-navbar > *, .mm-menu.mm-theme-dark em.mm-counter {
  color: rgba(255, 255, 255, 0.4);
}

.mm-menu.mm-theme-dark .mm-btn:after, .mm-menu.mm-theme-dark .mm-btn:before {
  border-color: rgba(255, 255, 255, 0.4);
}

.mm-menu.mm-theme-dark .mm-listview {
  border-color: rgba(0, 0, 0, 0.15);
}

.mm-menu.mm-theme-dark .mm-listview > li .mm-next:after {
  border-color: rgba(255, 255, 255, 0.4);
}

.mm-menu.mm-theme-dark .mm-listview > li a:not(.mm-next) {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);
  tap-highlight-color: rgba(0, 0, 0, 0.1);
}

.mm-menu.mm-theme-dark .mm-listview > li.mm-selected > a:not(.mm-next), .mm-menu.mm-theme-dark .mm-listview > li.mm-selected > span {
  background: rgba(0, 0, 0, 0.1);
}

.mm-menu.mm-theme-dark .mm-divider, .mm-menu.mm-theme-dark .mm-fixeddivider span, .mm-menu.mm-theme-dark .mm-listview > li.mm-opened.mm-vertical > .mm-panel, .mm-menu.mm-theme-dark .mm-listview > li.mm-opened.mm-vertical > a.mm-next, .mm-menu.mm-theme-dark.mm-vertical .mm-listview > li.mm-opened > .mm-panel, .mm-menu.mm-theme-dark.mm-vertical .mm-listview > li.mm-opened > a.mm-next {
  background: rgba(255, 255, 255, 0.05);
}

.mm-menu.mm-theme-dark label.mm-check:before {
  border-color: rgba(255, 255, 255, 0.8);
}

.mm-menu.mm-shadow-page.mm-theme-dark:after, .mm-menu.mm-shadow-panels .mm-panel.mm-opened:nth-child(n+2) {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

.mm-menu.mm-theme-dark .mm-search input {
  background: rgba(255, 255, 255, 0.3);
  color: rgba(255, 255, 255, 0.8);
}

.mm-menu.mm-theme-dark .mm-indexer a, .mm-menu.mm-theme-dark .mm-noresultsmsg {
  color: rgba(255, 255, 255, 0.4);
}

.mm-menu.mm-hoverselected.mm-theme-dark .mm-listview > li > a.mm-fullsubopen:hover + span, .mm-menu.mm-hoverselected.mm-theme-dark .mm-listview > li > a:not(.mm-fullsubopen):hover, .mm-menu.mm-parentselected.mm-theme-dark .mm-listview > li > a.mm-selected.mm-fullsubopen + a, .mm-menu.mm-parentselected.mm-theme-dark .mm-listview > li > a.mm-selected.mm-fullsubopen + span, .mm-menu.mm-parentselected.mm-theme-dark .mm-listview > li > a.mm-selected:not(.mm-fullsubopen) {
  background: rgba(0, 0, 0, 0.1);
}

.mm-menu.mm-theme-dark label.mm-toggle {
  background: rgba(0, 0, 0, 0.15);
}

.mm-menu.mm-theme-dark label.mm-toggle:before {
  background: #333;
}

.mm-menu.mm-theme-dark input.mm-toggle:checked ~ label.mm-toggle {
  background: #4bd963;
}

.mm-menu.mm-theme-white {
  background: #fff;
  border-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.6);
}

.mm-menu.mm-theme-white .mm-navbar a, .mm-menu.mm-theme-white .mm-navbar > *, .mm-menu.mm-theme-white em.mm-counter {
  color: rgba(0, 0, 0, 0.3);
}

.mm-menu.mm-theme-white .mm-btn:after, .mm-menu.mm-theme-white .mm-btn:before {
  border-color: rgba(0, 0, 0, 0.3);
}

.mm-menu.mm-theme-white .mm-listview {
  border-color: rgba(0, 0, 0, 0.1);
}

.mm-menu.mm-theme-white .mm-listview > li .mm-next:after {
  border-color: rgba(0, 0, 0, 0.3);
}

.mm-menu.mm-theme-white .mm-listview > li a:not(.mm-next) {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.05);
  tap-highlight-color: rgba(0, 0, 0, 0.05);
}

.mm-menu.mm-theme-white .mm-listview > li.mm-selected > a:not(.mm-next), .mm-menu.mm-theme-white .mm-listview > li.mm-selected > span {
  background: rgba(0, 0, 0, 0.05);
}

.mm-menu.mm-theme-white .mm-divider, .mm-menu.mm-theme-white .mm-fixeddivider span, .mm-menu.mm-theme-white .mm-listview > li.mm-opened.mm-vertical > .mm-panel, .mm-menu.mm-theme-white .mm-listview > li.mm-opened.mm-vertical > a.mm-next, .mm-menu.mm-theme-white.mm-vertical .mm-listview > li.mm-opened > .mm-panel, .mm-menu.mm-theme-white.mm-vertical .mm-listview > li.mm-opened > a.mm-next {
  background: rgba(0, 0, 0, 0.03);
}

.mm-menu.mm-theme-white label.mm-check:before {
  border-color: rgba(0, 0, 0, 0.6);
}

.mm-menu.mm-shadow-page.mm-theme-white:after, .mm-menu.mm-shadow-panels .mm-panel.mm-opened:nth-child(n+2) {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.mm-menu.mm-theme-white .mm-search input {
  background: rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.6);
}

.mm-menu.mm-theme-white .mm-indexer a, .mm-menu.mm-theme-white .mm-noresultsmsg {
  color: rgba(0, 0, 0, 0.3);
}

.mm-menu.mm-hoverselected.mm-theme-white .mm-listview > li > a.mm-fullsubopen:hover + span, .mm-menu.mm-hoverselected.mm-theme-white .mm-listview > li > a:not(.mm-fullsubopen):hover, .mm-menu.mm-parentselected.mm-theme-white .mm-listview > li > a.mm-selected.mm-fullsubopen + a, .mm-menu.mm-parentselected.mm-theme-white .mm-listview > li > a.mm-selected.mm-fullsubopen + span, .mm-menu.mm-parentselected.mm-theme-white .mm-listview > li > a.mm-selected:not(.mm-fullsubopen) {
  background: rgba(0, 0, 0, 0.05);
}

.mm-menu.mm-theme-white label.mm-toggle {
  background: rgba(0, 0, 0, 0.1);
}

.mm-menu.mm-theme-white label.mm-toggle:before {
  background: #fff;
}

.mm-menu.mm-theme-white input.mm-toggle:checked ~ label.mm-toggle {
  background: #4bd963;
}

.mm-menu.mm-theme-black {
  background: #000;
  border-color: rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.6);
}

.mm-menu.mm-theme-black .mm-navbar a, .mm-menu.mm-theme-black .mm-navbar > *, .mm-menu.mm-theme-black em.mm-counter {
  color: rgba(255, 255, 255, 0.4);
}

.mm-menu.mm-theme-black .mm-btn:after, .mm-menu.mm-theme-black .mm-btn:before {
  border-color: rgba(255, 255, 255, 0.4);
}

.mm-menu.mm-theme-black .mm-listview {
  border-color: rgba(255, 255, 255, 0.2);
}

.mm-menu.mm-theme-black .mm-listview > li .mm-next:after {
  border-color: rgba(255, 255, 255, 0.4);
}

.mm-menu.mm-theme-black .mm-listview > li a:not(.mm-next) {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0.3);
  tap-highlight-color: rgba(255, 255, 255, 0.3);
}

.mm-menu.mm-theme-black .mm-listview > li.mm-selected > a:not(.mm-next), .mm-menu.mm-theme-black .mm-listview > li.mm-selected > span {
  background: rgba(255, 255, 255, 0.3);
}

.mm-menu.mm-theme-black .mm-divider, .mm-menu.mm-theme-black .mm-fixeddivider span, .mm-menu.mm-theme-black .mm-listview > li.mm-opened.mm-vertical > .mm-panel, .mm-menu.mm-theme-black .mm-listview > li.mm-opened.mm-vertical > a.mm-next, .mm-menu.mm-theme-black.mm-vertical .mm-listview > li.mm-opened > .mm-panel, .mm-menu.mm-theme-black.mm-vertical .mm-listview > li.mm-opened > a.mm-next {
  background: rgba(255, 255, 255, 0.2);
}

.mm-menu.mm-theme-black label.mm-check:before {
  border-color: rgba(255, 255, 255, 0.6);
}

.mm-menu.mm-hoverselected.mm-theme-black .mm-listview > li > a.mm-fullsubopen:hover + span, .mm-menu.mm-hoverselected.mm-theme-black .mm-listview > li > a:not(.mm-fullsubopen):hover, .mm-menu.mm-keyboardfocus a:focus, .mm-menu.mm-parentselected.mm-theme-black .mm-listview > li > a.mm-selected.mm-fullsubopen + a, .mm-menu.mm-parentselected.mm-theme-black .mm-listview > li > a.mm-selected.mm-fullsubopen + span, .mm-menu.mm-parentselected.mm-theme-black .mm-listview > li > a.mm-selected:not(.mm-fullsubopen) {
  background: rgba(255, 255, 255, 0.3);
}

.mm-menu.mm-shadow-page.mm-theme-black:after {
  content: none;
  display: none;
}

.mm-menu.mm-shadow-panels .mm-panel.mm-opened:nth-child(n+2) {
  box-shadow: false;
}

.mm-menu.mm-theme-black .mm-search input {
  background: rgba(255, 255, 255, 0.3);
  color: rgba(255, 255, 255, 0.6);
}

.mm-menu.mm-theme-black .mm-indexer a, .mm-menu.mm-theme-black .mm-noresultsmsg {
  color: rgba(255, 255, 255, 0.4);
}

.mm-menu.mm-theme-black label.mm-toggle {
  background: rgba(255, 255, 255, 0.2);
}

.mm-menu.mm-theme-black label.mm-toggle:before {
  background: #000;
}

.mm-menu.mm-theme-black input.mm-toggle:checked ~ label.mm-toggle {
  background: #4bd963;
}

.mm-menu .mm-tileview.mm-listview:after, .mm-menu.mm-tileview .mm-listview:after {
  content: '';
  display: block;
  clear: both;
}

.mm-menu .mm-tileview.mm-listview > li > .mm-next:after, .mm-menu .mm-tileview.mm-listview > li > .mm-next:before, .mm-menu.mm-tileview .mm-listview > li > .mm-next:after, .mm-menu.mm-tileview .mm-listview > li > .mm-next:before, .mm-menu.mm-tileview .mm-panel:after {
  content: none;
  display: none;
}

.mm-menu .mm-tileview.mm-listview > li, .mm-menu.mm-tileview .mm-listview > li {
  width: 50%;
  height: 0;
  padding: 50% 0 0;
  float: left;
  position: relative;
}

.mm-menu .mm-tileview.mm-listview > li:after, .mm-menu.mm-tileview .mm-listview > li:after {
  left: 0;
  top: 0;
  border-right-width: 1px;
  border-right-style: solid;
  z-index: -1;
}

.mm-menu .mm-tileview.mm-listview > li.mm-tile-xs, .mm-menu.mm-tileview .mm-listview > li.mm-tile-xs {
  width: 12.5%;
  padding-top: 12.5%;
}

.mm-menu .mm-tileview.mm-listview > li.mm-tile-s, .mm-menu.mm-tileview .mm-listview > li.mm-tile-s {
  width: 25%;
  padding-top: 25%;
}

.mm-menu .mm-tileview.mm-listview > li.mm-tile-l, .mm-menu.mm-tileview .mm-listview > li.mm-tile-l {
  width: 75%;
  padding-top: 75%;
}

.mm-menu .mm-tileview.mm-listview > li.mm-tile-xl, .mm-menu.mm-tileview .mm-listview > li.mm-tile-xl {
  width: 100%;
  padding-top: 100%;
}

.mm-menu .mm-tileview.mm-listview > li > a, .mm-menu .mm-tileview.mm-listview > li > span, .mm-menu.mm-tileview .mm-listview > li > a, .mm-menu.mm-tileview .mm-listview > li > span {
  line-height: 1px;
  text-align: center;
  padding: 50% 10px 0;
  margin: 0;
  position: absolute;
  top: 0;
  right: 1px;
  bottom: 1px;
  left: 0;
}

.mm-menu .mm-tileview.mm-listview > li > .mm-next, .mm-menu.mm-tileview .mm-listview > li > .mm-next {
  width: auto;
}

.mm-menu.mm-tileview .mm-panel {
  padding-left: 0;
  padding-right: 0;
}

.mm-menu.mm-tileview .mm-listview {
  margin: 0;
}

/*
== malihu jquery custom scrollbar plugin ==
Plugin URI: http://manos.malihu.gr/jquery-custom-content-scroller
*/
/*
CONTENTS: 
	1. BASIC STYLE - Plugin's basic/essential CSS properties (normally, should not be edited). 
	2. VERTICAL SCROLLBAR - Positioning and dimensions of vertical scrollbar. 
	3. HORIZONTAL SCROLLBAR - Positioning and dimensions of horizontal scrollbar.
	4. VERTICAL AND HORIZONTAL SCROLLBARS - Positioning and dimensions of 2-axis scrollbars. 
	5. TRANSITIONS - CSS3 transitions for hover events, auto-expanded and auto-hidden scrollbars. 
	6. SCROLLBAR COLORS, OPACITY AND BACKGROUNDS 
		6.1 THEMES - Scrollbar colors, opacity, dimensions, backgrounds etc. via ready-to-use themes.
*/
/* 
------------------------------------------------------------------------------------------------------------------------
1. BASIC STYLE  
------------------------------------------------------------------------------------------------------------------------
*/
.mCustomScrollbar {
  -ms-touch-action: pinch-zoom;
  touch-action: pinch-zoom;
  /* direct pointer events to js */
}

.mCustomScrollbar.mCS_no_scrollbar, .mCustomScrollbar.mCS_touch_action {
  -ms-touch-action: auto;
  touch-action: auto;
}

.mCustomScrollBox {
  /* contains plugin's markup */
  position: relative;
  overflow: hidden;
  height: 100%;
  max-width: 100%;
  outline: none;
  direction: ltr;
}

.mCSB_container {
  /* contains the original content */
  overflow: hidden;
  width: auto;
  height: auto;
}

/* 
------------------------------------------------------------------------------------------------------------------------
2. VERTICAL SCROLLBAR 
y-axis
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_inside > .mCSB_container {
  margin-right: 30px;
}

.mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-right: 0;
}

/* non-visible scrollbar */
.mCS-dir-rtl > .mCSB_inside > .mCSB_container {
  /* RTL direction/left-side scrollbar */
  margin-right: 0;
  margin-left: 30px;
}

.mCS-dir-rtl > .mCSB_inside > .mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-left: 0;
}

/* RTL direction/left-side scrollbar */
.mCSB_scrollTools {
  /* contains scrollbar markup (draggable element, dragger rail, buttons etc.) */
  position: absolute;
  width: 16px;
  height: auto;
  left: auto;
  top: 0;
  right: 0;
  bottom: 0;
}

.mCSB_outside + .mCSB_scrollTools {
  right: -26px;
}

/* scrollbar position: outside */
.mCS-dir-rtl > .mCSB_inside > .mCSB_scrollTools,
.mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools {
  /* RTL direction/left-side scrollbar */
  right: auto;
  left: 0;
}

.mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools {
  left: -26px;
}

/* RTL direction/left-side scrollbar (scrollbar position: outside) */
.mCSB_scrollTools .mCSB_draggerContainer {
  /* contains the draggable element and dragger rail markup */
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: auto;
}

.mCSB_scrollTools a + .mCSB_draggerContainer {
  margin: 20px 0;
}

.mCSB_scrollTools .mCSB_draggerRail {
  width: 2px;
  height: 100%;
  margin: 0 auto;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
}

.mCSB_scrollTools .mCSB_dragger {
  /* the draggable element */
  cursor: pointer;
  width: 100%;
  height: 30px;
  /* minimum dragger height */
  z-index: 1;
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  /* the dragger element */
  position: relative;
  width: 4px;
  height: 100%;
  margin: 0 auto;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  text-align: center;
}

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  width: 12px;
  /* auto-expanded scrollbar */
}

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 8px;
  /* auto-expanded scrollbar */
}

.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown {
  display: block;
  position: absolute;
  height: 20px;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
  cursor: pointer;
}

.mCSB_scrollTools .mCSB_buttonDown {
  bottom: 0;
}

/* 
------------------------------------------------------------------------------------------------------------------------
3. HORIZONTAL SCROLLBAR 
x-axis
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_horizontal.mCSB_inside > .mCSB_container {
  margin-right: 0;
  margin-bottom: 30px;
}

.mCSB_horizontal.mCSB_outside > .mCSB_container {
  min-height: 100%;
}

.mCSB_horizontal > .mCSB_container.mCS_no_scrollbar_x.mCS_x_hidden {
  margin-bottom: 0;
}

/* non-visible scrollbar */
.mCSB_scrollTools.mCSB_scrollTools_horizontal {
  width: auto;
  height: 16px;
  top: auto;
  right: 0;
  bottom: 0;
  left: 0;
}

.mCustomScrollBox + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: -26px;
}

/* scrollbar position: outside */
.mCSB_scrollTools.mCSB_scrollTools_horizontal a + .mCSB_draggerContainer {
  margin: 0 20px;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 2px;
  margin: 7px 0;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 30px;
  /* minimum dragger width */
  height: 100%;
  left: 0;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 4px;
  margin: 6px auto;
}

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  height: 12px;
  /* auto-expanded scrollbar */
  margin: 2px auto;
}

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 8px;
  /* auto-expanded scrollbar */
  margin: 4px 0;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft,
.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
  display: block;
  position: absolute;
  width: 20px;
  height: 100%;
  overflow: hidden;
  margin: 0 auto;
  cursor: pointer;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft {
  left: 0;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
  right: 0;
}

/* 
------------------------------------------------------------------------------------------------------------------------
4. VERTICAL AND HORIZONTAL SCROLLBARS 
yx-axis 
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_container_wrapper {
  position: absolute;
  height: auto;
  width: auto;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-right: 30px;
  margin-bottom: 30px;
}

.mCSB_container_wrapper > .mCSB_container {
  padding-right: 30px;
  padding-bottom: 30px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_vertical {
  bottom: 20px;
}

.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  right: 20px;
}

/* non-visible horizontal scrollbar */
.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden + .mCSB_scrollTools.mCSB_scrollTools_vertical {
  bottom: 0;
}

/* non-visible vertical scrollbar/RTL direction/left-side scrollbar */
.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  right: 0;
}

/* RTL direction/left-side scrollbar */
.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  left: 20px;
}

/* non-visible scrollbar/RTL direction/left-side scrollbar */
.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  left: 0;
}

.mCS-dir-rtl > .mCSB_inside > .mCSB_container_wrapper {
  /* RTL direction/left-side scrollbar */
  margin-right: 0;
  margin-left: 30px;
}

.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden > .mCSB_container {
  padding-right: 0;
}

.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden > .mCSB_container {
  padding-bottom: 0;
}

.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-right: 0;
  /* non-visible scrollbar */
  margin-left: 0;
}

/* non-visible horizontal scrollbar */
.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden {
  margin-bottom: 0;
}

/* 
------------------------------------------------------------------------------------------------------------------------
5. TRANSITIONS  
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_scrollTools,
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonLeft,
.mCSB_scrollTools .mCSB_buttonRight {
  -webkit-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
  -moz-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
  -o-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
  transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
}

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail {
  -webkit-transition: width .2s ease-out .2s, height .2s ease-out .2s,  margin-left .2s ease-out .2s, margin-right .2s ease-out .2s,  margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
  -moz-transition: width .2s ease-out .2s, height .2s ease-out .2s,  margin-left .2s ease-out .2s, margin-right .2s ease-out .2s,  margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
  -o-transition: width .2s ease-out .2s, height .2s ease-out .2s,  margin-left .2s ease-out .2s, margin-right .2s ease-out .2s,  margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
  transition: width .2s ease-out .2s, height .2s ease-out .2s,  margin-left .2s ease-out .2s, margin-right .2s ease-out .2s,  margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
}

/* 
------------------------------------------------------------------------------------------------------------------------
6. SCROLLBAR COLORS, OPACITY AND BACKGROUNDS  
------------------------------------------------------------------------------------------------------------------------
*/
/* 
	----------------------------------------
	6.1 THEMES 
	----------------------------------------
	*/
/* default theme ("light") */
.mCSB_scrollTools {
  opacity: 0.75;
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)";
}

.mCS-autoHide > .mCustomScrollBox > .mCSB_scrollTools,
.mCS-autoHide > .mCustomScrollBox ~ .mCSB_scrollTools {
  opacity: 0;
  filter: "alpha(opacity=0)";
  -ms-filter: "alpha(opacity=0)";
}

.mCustomScrollbar > .mCustomScrollBox > .mCSB_scrollTools.mCSB_scrollTools_onDrag,
.mCustomScrollbar > .mCustomScrollBox ~ .mCSB_scrollTools.mCSB_scrollTools_onDrag,
.mCustomScrollBox:hover > .mCSB_scrollTools,
.mCustomScrollBox:hover ~ .mCSB_scrollTools,
.mCS-autoHide:hover > .mCustomScrollBox > .mCSB_scrollTools,
.mCS-autoHide:hover > .mCustomScrollBox ~ .mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=100)";
  -ms-filter: "alpha(opacity=100)";
}

.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.4);
  filter: "alpha(opacity=40)";
  -ms-filter: "alpha(opacity=40)";
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)";
}

.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85);
  filter: "alpha(opacity=85)";
  -ms-filter: "alpha(opacity=85)";
}

.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9);
  filter: "alpha(opacity=90)";
  -ms-filter: "alpha(opacity=90)";
}

.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonLeft,
.mCSB_scrollTools .mCSB_buttonRight {
  background-image: url(mCSB_buttons.png);
  /* css sprites */
  background-repeat: no-repeat;
  opacity: 0.4;
  filter: "alpha(opacity=40)";
  -ms-filter: "alpha(opacity=40)";
}

.mCSB_scrollTools .mCSB_buttonUp {
  background-position: 0 0;
  /* 
		sprites locations 
		light: 0 0, -16px 0, -32px 0, -48px 0, 0 -72px, -16px -72px, -32px -72px
		dark: -80px 0, -96px 0, -112px 0, -128px 0, -80px -72px, -96px -72px, -112px -72px
		*/
}

.mCSB_scrollTools .mCSB_buttonDown {
  background-position: 0 -20px;
  /* 
		sprites locations
		light: 0 -20px, -16px -20px, -32px -20px, -48px -20px, 0 -92px, -16px -92px, -32px -92px
		dark: -80px -20px, -96px -20px, -112px -20px, -128px -20px, -80px -92px, -96px -92px, -112 -92px
		*/
}

.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: 0 -40px;
  /* 
		sprites locations 
		light: 0 -40px, -20px -40px, -40px -40px, -60px -40px, 0 -112px, -20px -112px, -40px -112px
		dark: -80px -40px, -100px -40px, -120px -40px, -140px -40px, -80px -112px, -100px -112px, -120px -112px
		*/
}

.mCSB_scrollTools .mCSB_buttonRight {
  background-position: 0 -56px;
  /* 
		sprites locations 
		light: 0 -56px, -20px -56px, -40px -56px, -60px -56px, 0 -128px, -20px -128px, -40px -128px
		dark: -80px -56px, -100px -56px, -120px -56px, -140px -56px, -80px -128px, -100px -128px, -120px -128px
		*/
}

.mCSB_scrollTools .mCSB_buttonUp:hover,
.mCSB_scrollTools .mCSB_buttonDown:hover,
.mCSB_scrollTools .mCSB_buttonLeft:hover,
.mCSB_scrollTools .mCSB_buttonRight:hover {
  opacity: 0.75;
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)";
}

.mCSB_scrollTools .mCSB_buttonUp:active,
.mCSB_scrollTools .mCSB_buttonDown:active,
.mCSB_scrollTools .mCSB_buttonLeft:active,
.mCSB_scrollTools .mCSB_buttonRight:active {
  opacity: 0.9;
  filter: "alpha(opacity=90)";
  -ms-filter: "alpha(opacity=90)";
}

/* theme: "dark" */
.mCS-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15);
}

.mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
}

.mCS-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: rgba(0, 0, 0, 0.85);
}

.mCS-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: rgba(0, 0, 0, 0.9);
}

.mCS-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px 0;
}

.mCS-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -20px;
}

.mCS-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -40px;
}

.mCS-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -56px;
}

/* ---------------------------------------- */
/* theme: "light-2", "dark-2" */
.mCS-light-2.mCSB_scrollTools .mCSB_draggerRail,
.mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;
}

.mCS-light-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;
}

.mCS-light-2.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-light-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 4px;
  margin: 6px auto;
}

.mCS-light-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85);
}

.mCS-light-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-light-2.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9);
}

.mCS-light-2.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px 0;
}

.mCS-light-2.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -20px;
}

.mCS-light-2.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -40px;
}

.mCS-light-2.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -56px;
}

/* theme: "dark-2" */
.mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;
}

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;
}

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85);
}

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-2.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9);
}

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px 0;
}

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -20px;
}

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -40px;
}

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -56px;
}

/* ---------------------------------------- */
/* theme: "light-thick", "dark-thick" */
.mCS-light-thick.mCSB_scrollTools .mCSB_draggerRail,
.mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 4px;
  margin: 6px 0;
}

.mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 6px;
  margin: 5px auto;
}

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85);
}

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-light-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9);
}

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -16px 0;
}

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -16px -20px;
}

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -20px -40px;
}

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -20px -56px;
}

/* theme: "dark-thick" */
.mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85);
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9);
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -96px 0;
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -96px -20px;
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -100px -40px;
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -100px -56px;
}

/* ---------------------------------------- */
/* theme: "light-thin", "dark-thin" */
.mCS-light-thin.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}

.mCS-light-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 2px;
}

.mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
}

.mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 2px;
  margin: 7px auto;
}

/* theme "dark-thin" */
.mCS-dark-thin.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15);
}

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
}

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85);
}

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9);
}

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px 0;
}

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -20px;
}

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -40px;
}

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -56px;
}

/* ---------------------------------------- */
/* theme "rounded", "rounded-dark", "rounded-dots", "rounded-dots-dark" */
.mCS-rounded.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}

.mCS-rounded.mCSB_scrollTools .mCSB_dragger,
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger,
.mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger {
  height: 14px;
}

.mCS-rounded.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 14px;
  margin: 0 1px;
}

.mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 14px;
}

.mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 14px;
  margin: 1px 0;
}

.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  width: 16px;
  /* auto-expanded scrollbar */
  height: 16px;
  margin: -1px 0;
}

.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 4px;
  /* auto-expanded scrollbar */
}

.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  height: 16px;
  /* auto-expanded scrollbar */
  width: 16px;
  margin: 0 -1px;
}

.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 4px;
  /* auto-expanded scrollbar */
  margin: 6px 0;
}

.mCS-rounded.mCSB_scrollTools .mCSB_buttonUp {
  background-position: 0 -72px;
}

.mCS-rounded.mCSB_scrollTools .mCSB_buttonDown {
  background-position: 0 -92px;
}

.mCS-rounded.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: 0 -112px;
}

.mCS-rounded.mCSB_scrollTools .mCSB_buttonRight {
  background-position: 0 -128px;
}

/* theme "rounded-dark", "rounded-dots-dark" */
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
}

.mCS-rounded-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15);
}

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85);
}

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9);
}

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px -72px;
}

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -92px;
}

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -112px;
}

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -128px;
}

/* theme "rounded-dots", "rounded-dots-dark" */
.mCS-rounded-dots.mCSB_scrollTools_vertical .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools_vertical .mCSB_draggerRail {
  width: 4px;
}

.mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  background-color: transparent;
  background-position: center;
}

.mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAANElEQVQYV2NkIAAYiVbw//9/Y6DiM1ANJoyMjGdBbLgJQAX/kU0DKgDLkaQAvxW4HEvQFwCRcxIJK1XznAAAAABJRU5ErkJggg==");
  background-repeat: repeat-y;
  opacity: 0.3;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)";
}

.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  height: 4px;
  margin: 6px 0;
  background-repeat: repeat-x;
}

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -16px -72px;
}

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -16px -92px;
}

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -20px -112px;
}

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -20px -128px;
}

/* theme "rounded-dots-dark" */
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAALElEQVQYV2NkIAAYSVFgDFR8BqrBBEifBbGRTfiPZhpYjiQFBK3A6l6CvgAAE9kGCd1mvgEAAAAASUVORK5CYII=");
}

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -96px -72px;
}

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -96px -92px;
}

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -100px -112px;
}

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -100px -128px;
}

/* ---------------------------------------- */
/* theme "3d", "3d-dark", "3d-thick", "3d-thick-dark" */
.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-repeat: repeat-y;
  background-image: -moz-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0.5)), color-stop(100%, rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -o-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -ms-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
}

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  background-repeat: repeat-x;
  background-image: -moz-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0.5)), color-stop(100%, rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -o-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -ms-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
}

/* theme "3d", "3d-dark" */
.mCS-3d.mCSB_scrollTools_vertical .mCSB_dragger,
.mCS-3d-dark.mCSB_scrollTools_vertical .mCSB_dragger {
  height: 70px;
}

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 70px;
}

.mCS-3d.mCSB_scrollTools,
.mCS-3d-dark.mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)";
}

.mCS-3d.mCSB_scrollTools .mCSB_draggerRail,
.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
}

.mCS-3d.mCSB_scrollTools .mCSB_draggerRail,
.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail {
  width: 8px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.5), inset -1px 0 1px rgba(255, 255, 255, 0.2);
}

.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #555;
}

.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 8px;
}

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 8px;
  margin: 4px 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.5), inset 0 -1px 1px rgba(255, 255, 255, 0.2);
}

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 8px;
  margin: 4px auto;
}

.mCS-3d.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px;
}

.mCS-3d.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px;
}

.mCS-3d.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px;
}

.mCS-3d.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px;
}

/* theme "3d-dark" */
.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1);
}

.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
}

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px;
}

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px;
}

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px;
}

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px;
}

/* ---------------------------------------- */
/* theme: "3d-thick", "3d-thick-dark" */
.mCS-3d-thick.mCSB_scrollTools,
.mCS-3d-thick-dark.mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)";
}

.mCS-3d-thick.mCSB_scrollTools,
.mCS-3d-thick-dark.mCSB_scrollTools,
.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer {
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.mCSB_inside + .mCS-3d-thick.mCSB_scrollTools_vertical,
.mCSB_inside + .mCS-3d-thick-dark.mCSB_scrollTools_vertical {
  right: 1px;
}

.mCS-3d-thick.mCSB_scrollTools_vertical,
.mCS-3d-thick-dark.mCSB_scrollTools_vertical {
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5);
}

.mCS-3d-thick.mCSB_scrollTools_horizontal,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal {
  bottom: 1px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5);
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4);
  width: 12px;
  margin: 2px;
  position: absolute;
  height: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4);
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #555;
}

.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 12px;
  width: auto;
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1);
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent;
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px;
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px;
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px;
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px;
}

/* theme: "3d-thick-dark" */
.mCS-3d-thick-dark.mCSB_scrollTools {
  box-shadow: inset 0 0 14px rgba(0, 0, 0, 0.2);
}

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.2);
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4), inset -1px 0 0 rgba(0, 0, 0, 0.2);
}

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), inset 0 -1px 0 rgba(0, 0, 0, 0.2);
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #777;
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer {
  background-color: #fff;
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1);
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent;
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px;
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px;
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px;
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px;
}

/* ---------------------------------------- */
/* theme: "minimal", "minimal-dark" */
.mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical,
.mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical {
  right: 0;
  margin: 12px 0;
}

.mCustomScrollBox.mCS-minimal + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox.mCS-minimal + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox.mCS-minimal-dark + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox.mCS-minimal-dark + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: 0;
  margin: 0 12px;
}

/* RTL direction/left-side scrollbar */
.mCS-dir-rtl > .mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical,
.mCS-dir-rtl > .mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical {
  left: 0;
  right: auto;
}

.mCS-minimal.mCSB_scrollTools .mCSB_draggerRail,
.mCS-minimal-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent;
}

.mCS-minimal.mCSB_scrollTools_vertical .mCSB_dragger,
.mCS-minimal-dark.mCSB_scrollTools_vertical .mCSB_dragger {
  height: 50px;
}

.mCS-minimal.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-minimal-dark.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 50px;
}

.mCS-minimal.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.2);
  filter: "alpha(opacity=20)";
  -ms-filter: "alpha(opacity=20)";
}

.mCS-minimal.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-minimal.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.5);
  filter: "alpha(opacity=50)";
  -ms-filter: "alpha(opacity=50)";
}

/* theme: "minimal-dark" */
.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
  filter: "alpha(opacity=20)";
  -ms-filter: "alpha(opacity=20)";
}

.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.5);
  filter: "alpha(opacity=50)";
  -ms-filter: "alpha(opacity=50)";
}

/* ---------------------------------------- */
/* theme "light-3", "dark-3" */
.mCS-light-3.mCSB_scrollTools .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail {
  width: 6px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
}

.mCS-light-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
}

.mCS-light-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-light-3.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 6px;
  margin: 5px 0;
}

.mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 12px;
}

.mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 12px;
  margin: 2px 0;
}

.mCS-light-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px;
}

.mCS-light-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px;
}

.mCS-light-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px;
}

.mCS-light-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px;
}

/* theme "dark-3" */
.mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
}

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85);
}

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9);
}

.mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
}

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px;
}

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px;
}

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px;
}

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px;
}

/* ---------------------------------------- */
/* theme "inset", "inset-dark", "inset-2", "inset-2-dark", "inset-3", "inset-3-dark" */
.mCS-inset.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
  width: 12px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
}

.mCS-inset.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
  margin: 3px 5px;
  position: absolute;
  height: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.mCS-inset.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 6px;
  margin: 5px 3px;
  position: absolute;
  width: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.mCS-inset.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 12px;
  margin: 2px 0;
}

.mCS-inset.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px;
}

.mCS-inset.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px;
}

.mCS-inset.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px;
}

.mCS-inset.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px;
}

/* theme "inset-dark", "inset-2-dark", "inset-3-dark" */
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
}

.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85);
}

.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9);
}

.mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
}

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px;
}

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px;
}

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px;
}

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px;
}

/* theme "inset-2", "inset-2-dark" */
.mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: #fff;
  border-color: rgba(255, 255, 255, 0.2);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail {
  border-color: #000;
  border-color: rgba(0, 0, 0, 0.2);
}

/* theme "inset-3", "inset-3-dark" */
.mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.6);
}

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.6);
}

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
}

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85);
}

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9);
}

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
}

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85);
}

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9);
}

/* ---------------------------------------- */
.hamburger {
  font: inherit;
  display: inline-block;
  overflow: visible;
  margin: 0;
  cursor: pointer;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transition-duration: .15s;
  transition-duration: .15s;
  transition-property: opacity,-webkit-filter;
  -webkit-transition-property: opacity,-webkit-filter;
  transition-property: opacity,filter;
  transition-property: opacity,filter,-webkit-filter;
  text-transform: none;
  color: inherit;
  border: 0;
  background-color: transparent;
}

.hamburger:hover {
  opacity: .7;
}

.hamburger-box {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
}

.hamburger-inner {
  top: 50%;
  display: block;
  margin-top: -2px;
}

.hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
  position: absolute;
  width: 40px;
  height: 4px;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-duration: .15s;
  transition-duration: .15s;
  transition-property: -webkit-transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform,-webkit-transform;
  border-radius: 4px;
}

.hamburger-inner:after, .hamburger-inner:before {
  display: block;
  content: '';
}

.hamburger-inner:before {
  top: -10px;
}

.hamburger-inner:after {
  bottom: -10px;
}

.hamburger--3dx .hamburger-box {
  -webkit-perspective: 80px;
  perspective: 80px;
}

.hamburger--3dx .hamburger-inner {
  transition: background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s, -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-transition: background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s, -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s, -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dx .hamburger-inner:after, .hamburger--3dx .hamburger-inner:before {
  transition: -webkit-transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
  -webkit-transition: -webkit-transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
  transition: transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
  transition: transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s, -webkit-transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
}

.hamburger--3dx.is-active .hamburger-inner {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  background-color: transparent;
}

.hamburger--3dx.is-active .hamburger-inner:before {
  -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--3dx.is-active .hamburger-inner:after {
  -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}

.hamburger--3dx-r .hamburger-box {
  -webkit-perspective: 80px;
  perspective: 80px;
}

.hamburger--3dx-r .hamburger-inner {
  transition: background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s, -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-transition: background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s, -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s, -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dx-r .hamburger-inner:after, .hamburger--3dx-r .hamburger-inner:before {
  transition: -webkit-transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
  -webkit-transition: -webkit-transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
  transition: transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
  transition: transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s, -webkit-transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
}

.hamburger--3dx-r.is-active .hamburger-inner {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  background-color: transparent;
}

.hamburger--3dx-r.is-active .hamburger-inner:before {
  -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--3dx-r.is-active .hamburger-inner:after {
  -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}

.hamburger--3dy .hamburger-box {
  -webkit-perspective: 80px;
  perspective: 80px;
}

.hamburger--3dy .hamburger-inner {
  transition: background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s, -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-transition: background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s, -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s, -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dy .hamburger-inner:after, .hamburger--3dy .hamburger-inner:before {
  transition: -webkit-transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
  -webkit-transition: -webkit-transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
  transition: transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
  transition: transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s, -webkit-transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
}

.hamburger--3dy.is-active .hamburger-inner {
  -webkit-transform: rotateX(-180deg);
  transform: rotateX(-180deg);
  background-color: transparent;
}

.hamburger--3dy.is-active .hamburger-inner:before {
  -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--3dy.is-active .hamburger-inner:after {
  -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}

.hamburger--3dy-r .hamburger-box {
  -webkit-perspective: 80px;
  perspective: 80px;
}

.hamburger--3dy-r .hamburger-inner {
  transition: background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s, -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-transition: background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s, -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s, -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dy-r .hamburger-inner:after, .hamburger--3dy-r .hamburger-inner:before {
  transition: -webkit-transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
  -webkit-transition: -webkit-transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
  transition: transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
  transition: transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s, -webkit-transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
}

.hamburger--3dy-r.is-active .hamburger-inner {
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
  background-color: transparent;
}

.hamburger--3dy-r.is-active .hamburger-inner:before {
  -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--3dy-r.is-active .hamburger-inner:after {
  -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}

.hamburger--arrow.is-active .hamburger-inner:before {
  -webkit-transform: translate3d(-8px, 0, 0) rotate(-45deg) scaleX(0.7);
  transform: translate3d(-8px, 0, 0) rotate(-45deg) scaleX(0.7);
}

.hamburger--arrow.is-active .hamburger-inner:after {
  -webkit-transform: translate3d(-8px, 0, 0) rotate(45deg) scaleX(0.7);
  transform: translate3d(-8px, 0, 0) rotate(45deg) scaleX(0.7);
}

.hamburger--arrow-r.is-active .hamburger-inner:before {
  -webkit-transform: translate3d(8px, 0, 0) rotate(45deg) scaleX(0.7);
  transform: translate3d(8px, 0, 0) rotate(45deg) scaleX(0.7);
}

.hamburger--arrow-r.is-active .hamburger-inner:after {
  -webkit-transform: translate3d(8px, 0, 0) rotate(-45deg) scaleX(0.7);
  transform: translate3d(8px, 0, 0) rotate(-45deg) scaleX(0.7);
}

.hamburger--arrowalt .hamburger-inner:before {
  transition: top 0.1s ease 0.15s, -webkit-transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
  -webkit-transition: top 0.1s ease 0.15s, -webkit-transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: top 0.1s ease 0.15s, transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: top 0.1s ease 0.15s, transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hamburger--arrowalt .hamburger-inner:after {
  transition: bottom 0.1s ease 0.15s, -webkit-transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
  -webkit-transition: bottom 0.1s ease 0.15s, -webkit-transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: bottom 0.1s ease 0.15s, transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: bottom 0.1s ease 0.15s, transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hamburger--arrowalt.is-active .hamburger-inner:before {
  top: 0;
  transition: top 0.1s ease, -webkit-transform 0.15s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.1s;
  -webkit-transition: top 0.1s ease, -webkit-transform 0.15s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.1s;
  transition: top 0.1s ease, transform 0.15s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.1s;
  transition: top 0.1s ease, transform 0.15s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.1s, -webkit-transform 0.15s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.1s;
  -webkit-transform: translate3d(-8px, -10px, 0) rotate(-45deg) scaleX(0.7);
  transform: translate3d(-8px, -10px, 0) rotate(-45deg) scaleX(0.7);
}

.hamburger--arrowalt.is-active .hamburger-inner:after {
  bottom: 0;
  transition: bottom 0.1s ease, -webkit-transform 0.15s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.1s;
  -webkit-transition: bottom 0.1s ease, -webkit-transform 0.15s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.1s;
  transition: bottom 0.1s ease, transform 0.15s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.1s;
  transition: bottom 0.1s ease, transform 0.15s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.1s, -webkit-transform 0.15s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.1s;
  -webkit-transform: translate3d(-8px, 10px, 0) rotate(45deg) scaleX(0.7);
  transform: translate3d(-8px, 10px, 0) rotate(45deg) scaleX(0.7);
}

.hamburger--arrowalt-r .hamburger-inner:before {
  transition: top 0.1s ease 0.15s, -webkit-transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
  -webkit-transition: top 0.1s ease 0.15s, -webkit-transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: top 0.1s ease 0.15s, transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: top 0.1s ease 0.15s, transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hamburger--arrowalt-r .hamburger-inner:after {
  transition: bottom 0.1s ease 0.15s, -webkit-transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
  -webkit-transition: bottom 0.1s ease 0.15s, -webkit-transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: bottom 0.1s ease 0.15s, transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: bottom 0.1s ease 0.15s, transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hamburger--arrowalt-r.is-active .hamburger-inner:before {
  top: 0;
  transition: top 0.1s ease, -webkit-transform 0.15s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.1s;
  -webkit-transition: top 0.1s ease, -webkit-transform 0.15s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.1s;
  transition: top 0.1s ease, transform 0.15s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.1s;
  transition: top 0.1s ease, transform 0.15s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.1s, -webkit-transform 0.15s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.1s;
  -webkit-transform: translate3d(8px, -10px, 0) rotate(45deg) scaleX(0.7);
  transform: translate3d(8px, -10px, 0) rotate(45deg) scaleX(0.7);
}

.hamburger--arrowalt-r.is-active .hamburger-inner:after {
  bottom: 0;
  transition: bottom 0.1s ease, -webkit-transform 0.15s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.1s;
  -webkit-transition: bottom 0.1s ease, -webkit-transform 0.15s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.1s;
  transition: bottom 0.1s ease, transform 0.15s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.1s;
  transition: bottom 0.1s ease, transform 0.15s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.1s, -webkit-transform 0.15s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.1s;
  -webkit-transform: translate3d(8px, 10px, 0) rotate(-45deg) scaleX(0.7);
  transform: translate3d(8px, 10px, 0) rotate(-45deg) scaleX(0.7);
}

.hamburger--boring .hamburger-inner, .hamburger--boring .hamburger-inner:after, .hamburger--boring .hamburger-inner:before {
  -webkit-transition-property: none;
  transition-property: none;
}

.hamburger--boring.is-active .hamburger-inner {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.hamburger--boring.is-active .hamburger-inner:before {
  top: 0;
  opacity: 0;
}

.hamburger--boring.is-active .hamburger-inner:after {
  bottom: 0;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  -webkit-transition-delay: .15s;
  transition-delay: .15s;
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -webkit-transition-duration: .15s;
  transition-duration: .15s;
}

.hamburger--collapse .hamburger-inner:after {
  top: -20px;
  -webkit-transition: top 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, opacity 0.1s linear;
  transition: top 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, opacity 0.1s linear;
}

.hamburger--collapse .hamburger-inner:before {
  transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, -webkit-transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -webkit-transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, -webkit-transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse.is-active .hamburger-inner {
  -webkit-transition-delay: .32s;
  transition-delay: .32s;
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}

.hamburger--collapse.is-active .hamburger-inner:after {
  top: 0;
  -webkit-transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s linear 0.27s;
  transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s linear 0.27s;
  opacity: 0;
}

.hamburger--collapse.is-active .hamburger-inner:before {
  top: 0;
  transition: top 0.12s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.18s, -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.42s;
  -webkit-transition: top 0.12s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.18s, -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.42s;
  transition: top 0.12s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.18s, transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.42s;
  transition: top 0.12s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.18s, transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.42s, -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.42s;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.hamburger--collapse-r .hamburger-inner {
  top: auto;
  bottom: 0;
  -webkit-transition-delay: .15s;
  transition-delay: .15s;
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -webkit-transition-duration: .15s;
  transition-duration: .15s;
}

.hamburger--collapse-r .hamburger-inner:after {
  top: -20px;
  -webkit-transition: top 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, opacity 0.1s linear;
  transition: top 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, opacity 0.1s linear;
}

.hamburger--collapse-r .hamburger-inner:before {
  transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, -webkit-transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -webkit-transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, -webkit-transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse-r.is-active .hamburger-inner {
  -webkit-transition-delay: .32s;
  transition-delay: .32s;
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-transform: translate3d(0, -10px, 0) rotate(45deg);
  transform: translate3d(0, -10px, 0) rotate(45deg);
}

.hamburger--collapse-r.is-active .hamburger-inner:after {
  top: 0;
  -webkit-transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s linear 0.27s;
  transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s linear 0.27s;
  opacity: 0;
}

.hamburger--collapse-r.is-active .hamburger-inner:before {
  top: 0;
  transition: top 0.12s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.18s, -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.42s;
  -webkit-transition: top 0.12s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.18s, -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.42s;
  transition: top 0.12s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.18s, transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.42s;
  transition: top 0.12s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.18s, transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.42s, -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.42s;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.hamburger--elastic .hamburger-inner {
  top: 2px;
  -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -webkit-transition-duration: .4s;
  transition-duration: .4s;
}

.hamburger--elastic .hamburger-inner:before {
  top: 10px;
  -webkit-transition: opacity .15s ease .4s;
  transition: opacity .15s ease .4s;
}

.hamburger--elastic .hamburger-inner:after {
  top: 20px;
  transition: -webkit-transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55), -webkit-transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger--elastic.is-active .hamburger-inner {
  -webkit-transition-delay: .1s;
  transition-delay: .1s;
  -webkit-transform: translate3d(0, 10px, 0) rotate(135deg);
  transform: translate3d(0, 10px, 0) rotate(135deg);
}

.hamburger--elastic.is-active .hamburger-inner:before {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  opacity: 0;
}

.hamburger--elastic.is-active .hamburger-inner:after {
  -webkit-transition-delay: .1s;
  transition-delay: .1s;
  -webkit-transform: translate3d(0, -20px, 0) rotate(-270deg);
  transform: translate3d(0, -20px, 0) rotate(-270deg);
}

.hamburger--elastic-r .hamburger-inner {
  top: 2px;
  -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -webkit-transition-duration: .4s;
  transition-duration: .4s;
}

.hamburger--elastic-r .hamburger-inner:before {
  top: 10px;
  -webkit-transition: opacity .15s ease .4s;
  transition: opacity .15s ease .4s;
}

.hamburger--elastic-r .hamburger-inner:after {
  top: 20px;
  transition: -webkit-transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55), -webkit-transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger--elastic-r.is-active .hamburger-inner {
  -webkit-transition-delay: .1s;
  transition-delay: .1s;
  -webkit-transform: translate3d(0, 10px, 0) rotate(-135deg);
  transform: translate3d(0, 10px, 0) rotate(-135deg);
}

.hamburger--elastic-r.is-active .hamburger-inner:before {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  opacity: 0;
}

.hamburger--elastic-r.is-active .hamburger-inner:after {
  -webkit-transition-delay: .1s;
  transition-delay: .1s;
  -webkit-transform: translate3d(0, -20px, 0) rotate(270deg);
  transform: translate3d(0, -20px, 0) rotate(270deg);
}

.hamburger--emphatic {
  overflow: hidden;
}

.hamburger--emphatic .hamburger-inner {
  -webkit-transition: background-color .2s ease-in .25s;
  transition: background-color .2s ease-in .25s;
}

.hamburger--emphatic .hamburger-inner:before {
  left: 0;
  transition: top 0.05s linear 0.2s, left 0.2s ease-in 0.25s, -webkit-transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  -webkit-transition: top 0.05s linear 0.2s, left 0.2s ease-in 0.25s, -webkit-transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s linear 0.2s, left 0.2s ease-in 0.25s;
  transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s linear 0.2s, left 0.2s ease-in 0.25s, -webkit-transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335);
}

.hamburger--emphatic .hamburger-inner:after {
  top: 10px;
  right: 0;
  transition: top 0.05s linear 0.2s, right 0.2s ease-in 0.25s, -webkit-transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  -webkit-transition: top 0.05s linear 0.2s, right 0.2s ease-in 0.25s, -webkit-transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s linear 0.2s, right 0.2s ease-in 0.25s;
  transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s linear 0.2s, right 0.2s ease-in 0.25s, -webkit-transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335);
}

.hamburger--emphatic.is-active .hamburger-inner {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  background-color: transparent;
}

.hamburger--emphatic.is-active .hamburger-inner:before {
  top: -80px;
  left: -80px;
  transition: left 0.2s ease-out, top 0.05s linear 0.2s, -webkit-transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s;
  -webkit-transition: left 0.2s ease-out, top 0.05s linear 0.2s, -webkit-transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s;
  transition: left 0.2s ease-out, top 0.05s linear 0.2s, transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s;
  transition: left 0.2s ease-out, top 0.05s linear 0.2s, transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s, -webkit-transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s;
  -webkit-transform: translate3d(80px, 80px, 0) rotate(45deg);
  transform: translate3d(80px, 80px, 0) rotate(45deg);
}

.hamburger--emphatic.is-active .hamburger-inner:after {
  top: -80px;
  right: -80px;
  transition: right 0.2s ease-out, top 0.05s linear 0.2s, -webkit-transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s;
  -webkit-transition: right 0.2s ease-out, top 0.05s linear 0.2s, -webkit-transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s;
  transition: right 0.2s ease-out, top 0.05s linear 0.2s, transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s;
  transition: right 0.2s ease-out, top 0.05s linear 0.2s, transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s, -webkit-transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s;
  -webkit-transform: translate3d(-80px, 80px, 0) rotate(-45deg);
  transform: translate3d(-80px, 80px, 0) rotate(-45deg);
}

.hamburger--emphatic-r {
  overflow: hidden;
}

.hamburger--emphatic-r .hamburger-inner {
  -webkit-transition: background-color .2s ease-in .25s;
  transition: background-color .2s ease-in .25s;
}

.hamburger--emphatic-r .hamburger-inner:before {
  left: 0;
  transition: top 0.05s linear 0.2s, left 0.2s ease-in 0.25s, -webkit-transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  -webkit-transition: top 0.05s linear 0.2s, left 0.2s ease-in 0.25s, -webkit-transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s linear 0.2s, left 0.2s ease-in 0.25s;
  transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s linear 0.2s, left 0.2s ease-in 0.25s, -webkit-transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335);
}

.hamburger--emphatic-r .hamburger-inner:after {
  top: 10px;
  right: 0;
  transition: top 0.05s linear 0.2s, right 0.2s ease-in 0.25s, -webkit-transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  -webkit-transition: top 0.05s linear 0.2s, right 0.2s ease-in 0.25s, -webkit-transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s linear 0.2s, right 0.2s ease-in 0.25s;
  transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s linear 0.2s, right 0.2s ease-in 0.25s, -webkit-transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335);
}

.hamburger--emphatic-r.is-active .hamburger-inner {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  background-color: transparent;
}

.hamburger--emphatic-r.is-active .hamburger-inner:before {
  top: 80px;
  left: -80px;
  transition: left 0.2s ease-out, top 0.05s linear 0.2s, -webkit-transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s;
  -webkit-transition: left 0.2s ease-out, top 0.05s linear 0.2s, -webkit-transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s;
  transition: left 0.2s ease-out, top 0.05s linear 0.2s, transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s;
  transition: left 0.2s ease-out, top 0.05s linear 0.2s, transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s, -webkit-transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s;
  -webkit-transform: translate3d(80px, -80px, 0) rotate(-45deg);
  transform: translate3d(80px, -80px, 0) rotate(-45deg);
}

.hamburger--emphatic-r.is-active .hamburger-inner:after {
  top: 80px;
  right: -80px;
  transition: right 0.2s ease-out, top 0.05s linear 0.2s, -webkit-transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s;
  -webkit-transition: right 0.2s ease-out, top 0.05s linear 0.2s, -webkit-transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s;
  transition: right 0.2s ease-out, top 0.05s linear 0.2s, transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s;
  transition: right 0.2s ease-out, top 0.05s linear 0.2s, transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s, -webkit-transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s;
  -webkit-transform: translate3d(-80px, -80px, 0) rotate(45deg);
  transform: translate3d(-80px, -80px, 0) rotate(45deg);
}

.hamburger--slider .hamburger-inner {
  top: 2px;
}

.hamburger--slider .hamburger-inner:before {
  top: 10px;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-duration: .2s;
  transition-duration: .2s;
  transition-property: opacity,-webkit-transform;
  -webkit-transition-property: opacity,-webkit-transform;
  transition-property: transform,opacity;
  transition-property: transform,opacity,-webkit-transform;
}

.hamburger--slider .hamburger-inner:after {
  top: 20px;
}

.hamburger--slider.is-active .hamburger-inner {
  -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--slider.is-active .hamburger-inner:before {
  -webkit-transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
  transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
  opacity: 0;
}

.hamburger--slider.is-active .hamburger-inner:after {
  -webkit-transform: translate3d(0, -20px, 0) rotate(-90deg);
  transform: translate3d(0, -20px, 0) rotate(-90deg);
}

.hamburger--slider-r .hamburger-inner {
  top: 2px;
}

.hamburger--slider-r .hamburger-inner:before {
  top: 10px;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-duration: .2s;
  transition-duration: .2s;
  transition-property: opacity,-webkit-transform;
  -webkit-transition-property: opacity,-webkit-transform;
  transition-property: transform,opacity;
  transition-property: transform,opacity,-webkit-transform;
}

.hamburger--slider-r .hamburger-inner:after {
  top: 20px;
}

.hamburger--slider-r.is-active .hamburger-inner {
  -webkit-transform: translate3d(0, 10px, 0) rotate(-45deg);
  transform: translate3d(0, 10px, 0) rotate(-45deg);
}

.hamburger--slider-r.is-active .hamburger-inner:before {
  -webkit-transform: rotate(45deg) translate3d(5.71429px, -6px, 0);
  transform: rotate(45deg) translate3d(5.71429px, -6px, 0);
  opacity: 0;
}

.hamburger--slider-r.is-active .hamburger-inner:after {
  -webkit-transform: translate3d(0, -20px, 0) rotate(90deg);
  transform: translate3d(0, -20px, 0) rotate(90deg);
}

.hamburger--spring .hamburger-inner {
  top: 2px;
  -webkit-transition: background-color 0s linear .15s;
  transition: background-color 0s linear .15s;
}

.hamburger--spring .hamburger-inner:before {
  top: 10px;
  transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, -webkit-transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -webkit-transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, -webkit-transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring .hamburger-inner:after {
  top: 20px;
  transition: top 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, -webkit-transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -webkit-transition: top 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, -webkit-transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring.is-active .hamburger-inner {
  -webkit-transition-delay: .32s;
  transition-delay: .32s;
  background-color: transparent;
}

.hamburger--spring.is-active .hamburger-inner:before {
  top: 0;
  transition: top 0.12s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.18s, -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.32s;
  -webkit-transition: top 0.12s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.18s, -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.32s;
  transition: top 0.12s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.18s, transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.32s;
  transition: top 0.12s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.18s, transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.32s, -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.32s;
  -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--spring.is-active .hamburger-inner:after {
  top: 0;
  transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.32s;
  -webkit-transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.32s;
  transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.32s;
  transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.32s, -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.32s;
  -webkit-transform: translate3d(0, 10px, 0) rotate(-45deg);
  transform: translate3d(0, 10px, 0) rotate(-45deg);
}

.hamburger--spring-r .hamburger-inner {
  top: auto;
  bottom: 0;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -webkit-transition-duration: .15s;
  transition-duration: .15s;
}

.hamburger--spring-r .hamburger-inner:after {
  top: -20px;
  -webkit-transition: top 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, opacity 0s linear;
  transition: top 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, opacity 0s linear;
}

.hamburger--spring-r .hamburger-inner:before {
  transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, -webkit-transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -webkit-transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, -webkit-transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring-r.is-active .hamburger-inner {
  -webkit-transition-delay: .32s;
  transition-delay: .32s;
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}

.hamburger--spring-r.is-active .hamburger-inner:after {
  top: 0;
  -webkit-transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0s linear 0.32s;
  transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0s linear 0.32s;
  opacity: 0;
}

.hamburger--spring-r.is-active .hamburger-inner:before {
  top: 0;
  transition: top 0.12s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.18s, -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.32s;
  -webkit-transition: top 0.12s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.18s, -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.32s;
  transition: top 0.12s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.18s, transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.32s;
  transition: top 0.12s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.18s, transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.32s, -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.32s;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.hamburger--stand .hamburger-inner {
  transition: background-color 0s linear 0.1s, -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0.22s;
  -webkit-transition: background-color 0s linear 0.1s, -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0.22s;
  transition: transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0.22s, background-color 0s linear 0.1s;
  transition: transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0.22s, background-color 0s linear 0.1s, -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0.22s;
}

.hamburger--stand .hamburger-inner:before {
  transition: top 0.1s ease-in 0.1s, -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  -webkit-transition: top 0.1s ease-in 0.1s, -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  transition: top 0.1s ease-in 0.1s, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  transition: top 0.1s ease-in 0.1s, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s, -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
}

.hamburger--stand .hamburger-inner:after {
  transition: bottom 0.1s ease-in 0.1s, -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  -webkit-transition: bottom 0.1s ease-in 0.1s, -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  transition: bottom 0.1s ease-in 0.1s, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  transition: bottom 0.1s ease-in 0.1s, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s, -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
}

.hamburger--stand.is-active .hamburger-inner {
  transition: background-color 0s linear 0.22s, -webkit-transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  -webkit-transition: background-color 0s linear 0.22s, -webkit-transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  transition: transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, background-color 0s linear 0.22s;
  transition: transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, background-color 0s linear 0.22s, -webkit-transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  background-color: transparent;
}

.hamburger--stand.is-active .hamburger-inner:before {
  top: 0;
  transition: top 0.1s ease-out 0.12s, -webkit-transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  -webkit-transition: top 0.1s ease-out 0.12s, -webkit-transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  transition: top 0.1s ease-out 0.12s, transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  transition: top 0.1s ease-out 0.12s, transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s, -webkit-transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.hamburger--stand.is-active .hamburger-inner:after {
  bottom: 0;
  transition: bottom 0.1s ease-out 0.12s, -webkit-transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  -webkit-transition: bottom 0.1s ease-out 0.12s, -webkit-transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  transition: bottom 0.1s ease-out 0.12s, transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  transition: bottom 0.1s ease-out 0.12s, transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s, -webkit-transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.hamburger--stand-r .hamburger-inner {
  transition: background-color 0s linear 0.1s, -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0.22s;
  -webkit-transition: background-color 0s linear 0.1s, -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0.22s;
  transition: transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0.22s, background-color 0s linear 0.1s;
  transition: transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0.22s, background-color 0s linear 0.1s, -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0.22s;
}

.hamburger--stand-r .hamburger-inner:before {
  transition: top 0.1s ease-in 0.1s, -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  -webkit-transition: top 0.1s ease-in 0.1s, -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  transition: top 0.1s ease-in 0.1s, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  transition: top 0.1s ease-in 0.1s, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s, -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
}

.hamburger--stand-r .hamburger-inner:after {
  transition: bottom 0.1s ease-in 0.1s, -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  -webkit-transition: bottom 0.1s ease-in 0.1s, -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  transition: bottom 0.1s ease-in 0.1s, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  transition: bottom 0.1s ease-in 0.1s, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s, -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
}

.hamburger--stand-r.is-active .hamburger-inner {
  transition: background-color 0s linear 0.22s, -webkit-transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  -webkit-transition: background-color 0s linear 0.22s, -webkit-transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  transition: transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, background-color 0s linear 0.22s;
  transition: transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, background-color 0s linear 0.22s, -webkit-transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  background-color: transparent;
}

.hamburger--stand-r.is-active .hamburger-inner:before {
  top: 0;
  transition: top 0.1s ease-out 0.12s, -webkit-transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  -webkit-transition: top 0.1s ease-out 0.12s, -webkit-transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  transition: top 0.1s ease-out 0.12s, transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  transition: top 0.1s ease-out 0.12s, transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s, -webkit-transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.hamburger--stand-r.is-active .hamburger-inner:after {
  bottom: 0;
  transition: bottom 0.1s ease-out 0.12s, -webkit-transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  -webkit-transition: bottom 0.1s ease-out 0.12s, -webkit-transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  transition: bottom 0.1s ease-out 0.12s, transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  transition: bottom 0.1s ease-out 0.12s, transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s, -webkit-transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.hamburger--spin .hamburger-inner {
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
}

.hamburger--spin .hamburger-inner:before {
  -webkit-transition: top .1s ease-in .34s,opacity .1s ease-in;
  transition: top .1s ease-in .34s,opacity .1s ease-in;
}

.hamburger--spin .hamburger-inner:after {
  transition: bottom 0.1s ease-in 0.34s, -webkit-transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -webkit-transition: bottom 0.1s ease-in 0.34s, -webkit-transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.1s ease-in 0.34s, transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.1s ease-in 0.34s, transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin.is-active .hamburger-inner {
  -webkit-transition-delay: .14s;
  transition-delay: .14s;
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg);
}

.hamburger--spin.is-active .hamburger-inner:before {
  top: 0;
  -webkit-transition: top .1s ease-out,opacity .1s ease-out .14s;
  transition: top .1s ease-out,opacity .1s ease-out .14s;
  opacity: 0;
}

.hamburger--spin.is-active .hamburger-inner:after {
  bottom: 0;
  transition: bottom 0.1s ease-out, -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0.14s;
  -webkit-transition: bottom 0.1s ease-out, -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0.14s;
  transition: bottom 0.1s ease-out, transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0.14s;
  transition: bottom 0.1s ease-out, transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0.14s, -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0.14s;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.hamburger--spin-r .hamburger-inner {
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
}

.hamburger--spin-r .hamburger-inner:before {
  -webkit-transition: top .1s ease-in .34s,opacity .1s ease-in;
  transition: top .1s ease-in .34s,opacity .1s ease-in;
}

.hamburger--spin-r .hamburger-inner:after {
  transition: bottom 0.1s ease-in 0.34s, -webkit-transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -webkit-transition: bottom 0.1s ease-in 0.34s, -webkit-transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.1s ease-in 0.34s, transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.1s ease-in 0.34s, transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin-r.is-active .hamburger-inner {
  -webkit-transition-delay: .14s;
  transition-delay: .14s;
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-transform: rotate(-225deg);
  transform: rotate(-225deg);
}

.hamburger--spin-r.is-active .hamburger-inner:before {
  top: 0;
  -webkit-transition: top .1s ease-out,opacity .1s ease-out .14s;
  transition: top .1s ease-out,opacity .1s ease-out .14s;
  opacity: 0;
}

.hamburger--spin-r.is-active .hamburger-inner:after {
  bottom: 0;
  transition: bottom 0.1s ease-out, -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0.14s;
  -webkit-transition: bottom 0.1s ease-out, -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0.14s;
  transition: bottom 0.1s ease-out, transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0.14s;
  transition: bottom 0.1s ease-out, transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0.14s, -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0.14s;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.hamburger--squeeze .hamburger-inner {
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -webkit-transition-duration: .1s;
  transition-duration: .1s;
}

.hamburger--squeeze .hamburger-inner:before {
  -webkit-transition: top .1s ease .14s,opacity .1s ease;
  transition: top .1s ease .14s,opacity .1s ease;
}

.hamburger--squeeze .hamburger-inner:after {
  transition: bottom 0.1s ease 0.14s, -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -webkit-transition: bottom 0.1s ease 0.14s, -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.1s ease 0.14s, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.1s ease 0.14s, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--squeeze.is-active .hamburger-inner {
  -webkit-transition-delay: .14s;
  transition-delay: .14s;
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.hamburger--squeeze.is-active .hamburger-inner:before {
  top: 0;
  -webkit-transition: top .1s ease,opacity .1s ease .14s;
  transition: top .1s ease,opacity .1s ease .14s;
  opacity: 0;
}

.hamburger--squeeze.is-active .hamburger-inner:after {
  bottom: 0;
  transition: bottom 0.1s ease, -webkit-transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.14s;
  -webkit-transition: bottom 0.1s ease, -webkit-transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.14s;
  transition: bottom 0.1s ease, transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.14s;
  transition: bottom 0.1s ease, transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.14s, -webkit-transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.14s;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.hamburger--vortex .hamburger-inner {
  -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
}

.hamburger--vortex .hamburger-inner:after, .hamburger--vortex .hamburger-inner:before {
  -webkit-transition-delay: .1s;
  transition-delay: .1s;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
}

.hamburger--vortex .hamburger-inner:before {
  -webkit-transition-property: top,opacity;
  transition-property: top,opacity;
}

.hamburger--vortex .hamburger-inner:after {
  transition-property: bottom,-webkit-transform;
  -webkit-transition-property: bottom,-webkit-transform;
  transition-property: bottom,transform;
  transition-property: bottom,transform,-webkit-transform;
}

.hamburger--vortex.is-active .hamburger-inner {
  -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  -webkit-transform: rotate(765deg);
  transform: rotate(765deg);
}

.hamburger--vortex.is-active .hamburger-inner:after, .hamburger--vortex.is-active .hamburger-inner:before {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.hamburger--vortex.is-active .hamburger-inner:before {
  top: 0;
  opacity: 0;
}

.hamburger--vortex.is-active .hamburger-inner:after {
  bottom: 0;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.hamburger--vortex-r .hamburger-inner {
  -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
}

.hamburger--vortex-r .hamburger-inner:after, .hamburger--vortex-r .hamburger-inner:before {
  -webkit-transition-delay: .1s;
  transition-delay: .1s;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
}

.hamburger--vortex-r .hamburger-inner:before {
  -webkit-transition-property: top,opacity;
  transition-property: top,opacity;
}

.hamburger--vortex-r .hamburger-inner:after {
  transition-property: bottom,-webkit-transform;
  -webkit-transition-property: bottom,-webkit-transform;
  transition-property: bottom,transform;
  transition-property: bottom,transform,-webkit-transform;
}

.hamburger--vortex-r.is-active .hamburger-inner {
  -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  -webkit-transform: rotate(-765deg);
  transform: rotate(-765deg);
}

.hamburger--vortex-r.is-active .hamburger-inner:after, .hamburger--vortex-r.is-active .hamburger-inner:before {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.hamburger--vortex-r.is-active .hamburger-inner:before {
  top: 0;
  opacity: 0;
}

.hamburger--vortex-r.is-active .hamburger-inner:after {
  bottom: 0;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
